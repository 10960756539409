import React, { FC } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { IonApp } from '@ionic/react'
import './theme/tailwind.css'
import './theme/variables.css'
/* Core CSS required for Ionic components to work properly */
// import '@ionic/react/css/core.css'
import Homepage from './pages/homepage/Homepage'
import Users from './pages/adminDashboard/Users'
import Login from './pages/login/Login'
import PrivateRoute from './components/privateRoute/privateRoute'
import NavBar from './components/navBar/NavBar'
import { AuthProvider, OrderProvider } from './services/context'
import Checkout from './pages/checkout/Checkout'
import OrderHistory from './pages/orderHistory/OrderHistory'
import OrderConfirm from './components/orderConfirm/OrderConfirm'
import Contact from './pages/contact/Contact'
import Help from './pages/help/Help'
import ForgotPassword from './pages/forgotPassword/ForgotPassword'
import Signup from './pages/signup/Signup'
import NotFound from './components/notFound/NotFound'
import Settings from './pages/adminDashboard/Settings'
import AdminOnlyRoute from './components/adminOnlyRoute/AdminOnlyRoute'
import MyAccount from './pages/myAccount/MyAccount'

const App: FC = () => (
  <AuthProvider>
    <IonApp className="w-full  h-full">
      <OrderProvider>
        <Router>
          <NavBar />
          <div className="max-w-screen-2xl mx-auto ">
            <Switch>
              <PrivateRoute exact path="/" component={Homepage} />
              <PrivateRoute path="/checkout" component={Checkout} />
              <PrivateRoute path="/orders" component={OrderHistory} />
              <AdminOnlyRoute path="/settings" component={Settings} />
              <AdminOnlyRoute path="/users" component={Users} />
              <PrivateRoute path="/confirm" component={OrderConfirm} />
              <Route path="/login" component={Login} />
              <Route path="/my-account" component={MyAccount} />
              <Route path="/signup" component={Signup} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/contact" component={Contact} />
              <Route path="/help" component={Help} />
              <Route exact path="*" component={NotFound} />
            </Switch>
          </div>
        </Router>
      </OrderProvider>
    </IonApp>
  </AuthProvider>
)

export default App
