import React from 'react'
import { breadCrumbStyles } from '../../theme/theme'

interface IBreadCrumb {
  category: string
  name?: string
  subcategory?: string
  showProducts: boolean
  onClick(item: any): void
}

const Breadcrumb = (props: IBreadCrumb) => {
  const { category, name, subcategory, onClick, showProducts } = props
  const List = [name, category, subcategory, showProducts && 'products']
  const FList = List.filter(x => x)
  return (
    <div className="mt-16 flex self-start flex-wrap">
      {FList.map((item, index) => {
        const lastIndex = FList.length - 1
        return (
          <div
            onClick={() => onClick(item)}
            key={index}
            className={
              item && index < lastIndex
                ? breadCrumbStyles['defaultClass']
                : breadCrumbStyles['lastItemClass']
            }>
            {item && index < lastIndex ? item + ' / ' : item}
          </div>
        )
      })}
    </div>
  )
}

export default Breadcrumb
