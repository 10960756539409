import React, { useContext, useEffect, useState } from 'react'
import { IonIcon } from '@ionic/react'
import { chevronDown } from 'ionicons/icons'
import { Collapse } from 'react-collapse'
import { CheckoutProductStyle } from '../../../theme/theme'
import { OrderContext } from '../../../services/context/orderContext/OrderContext'
import Modal from '../../modal/Modal'

interface ICheckoutOrderList {
  subtotal: number
  totalPrice: number
  dashboardVariables: {
    cabinetAssembleFee: number
    pantryAssembleFee: number
    customCabinetAssembleFee: number
    touchUpKit: number
  }
  setAssemblePrice: (price: number) => void
  assemblePrice: number
  isAssemble: boolean
  isTaxable: boolean
  salesTax: number
  isTouchUpKit: boolean
}
const CheckoutOrderList = (props: ICheckoutOrderList) => {
  const {
    subtotal,
    totalPrice,
    dashboardVariables,
    setAssemblePrice,
    assemblePrice,
    isAssemble,
    isTouchUpKit,
    isTaxable,
    salesTax
  } = props
  const order = useContext(OrderContext)
  const [isEditing, setIsEditing] = useState(false)
  const [accordionStyle, setAccordionStyle] = useState('')
  const [accordionDestroy, setAccordionDestroy] = useState(false)
  const [product, setProduct] = useState({})
  const [styles, setStyles] = useState([] as any)

  const index = order.currentOrder.indexOf(product)

  // ----------------------- effect for show or hide product  accordion base on screen width-------------------
  useEffect(() => {
    window.innerWidth >= 1024
      ? setAccordionDestroy(true)
      : setAccordionDestroy(false)
    const onResize = () => {
      window.innerWidth >= 1024
        ? setAccordionDestroy(true)
        : setAccordionDestroy(false)
    }
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [accordionDestroy])
  // ----------------------- effect for finding product styles and add them to a list for mapping in order list -------------------
  useEffect(() => {
    const styleList = [] as any
    if (order.currentOrder.length > 0) {
      order.currentOrder.forEach((item: any) => {
        styleList.indexOf(item.type) === -1 && styleList.push(item.type)
      })
      return setStyles(styleList)
    }
    return setStyles([])
  }, [order.currentOrder])

  useEffect(() => {
    const cabinetFee = Number(dashboardVariables.cabinetAssembleFee)
    const pantryFee = Number(dashboardVariables.pantryAssembleFee)
    const customFee = Number(dashboardVariables.customCabinetAssembleFee)
    let totalAssemblePrice: number = 0

    return order.currentOrder.forEach((item: any) => {
      if (item.isAssemble) {
        const assembleFee = () => {
          if (item.isPantryAssemble) {
            return item.isCustom ? customFee : pantryFee
          } else {
            return item.isCustom ? customFee : cabinetFee
          }
        }
        totalAssemblePrice =
          Number(totalAssemblePrice) + Number(assembleFee() * item.qty)
      }
      setAssemblePrice(totalAssemblePrice)
    })
  }, [
    order.currentOrder,
    dashboardVariables.cabinetAssembleFee,
    dashboardVariables.pantryAssembleFee,
    dashboardVariables.customCabinetAssembleFee
  ])

  const toggleAccordion = (val: string) => {
    accordionStyle === val ? setAccordionStyle('') : setAccordionStyle(val)
  }
  const toggleEdit = () => {
    setIsEditing(!isEditing)
  }
  return (
    <div className="desktop:w-3/5">
      <h2 className="text-left mb-12">Order List</h2>
      <div className="flex flex-col px-7  pb-14 bg-white shadow-containerShadow rounded-2xl">
        {styles.map((style: string) => {
          return (
            <div key={style}>
              <h2 className=" relative mt-10 py-4">
                <span>{style}</span>
                <span
                  onClick={() => toggleAccordion(style)}
                  className="absolute  left-0 right-0 bottom-0 flex justify-end items-center top-0 desktop:hidden">
                  <IonIcon
                    className={
                      accordionStyle === style
                        ? 'transform rotate-180 transition-all duration-500 '
                        : ' transition-all duration-500'
                    }
                    icon={chevronDown}
                  />
                </span>
              </h2>
              <Collapse
                isOpened={accordionDestroy ? true : accordionStyle === style}>
                {order.currentOrder.map((item: any, i: number) => {
                  return item.type === style ? (
                    <div
                      key={item.sku + i}
                      className="border-b relative cursor-pointer justify-between flex items-center flex-col tablet:flex-row py-7"
                      onClick={() => {
                        setProduct(item)
                        toggleEdit()
                      }}>
                      <div className=" w-full tablet:ml-11 tablet:w-1/2">
                        <h2 className={CheckoutProductStyle['grayTitle']}>
                          {item.sku}
                        </h2>
                        <h2 className={CheckoutProductStyle['attributes']}>
                          {item.name}
                        </h2>
                        <div className={CheckoutProductStyle['description']}>
                          {item.description}
                        </div>
                      </div>
                      <div
                        className={
                          'flex w-full  mt-4 tablet:my-auto tablet:w-1/2 justify-between'
                        }>
                        {(item.hinge || item.drawer || item.isCustom) && (
                          <div className={' flex gap-2 absolute top-4 right-0'}>
                            {item.isCustom && (
                              <span
                                title="Product is custom size."
                                className=" px-2 bg-white w-auto text-txtBlack text-sm text-center font-bold h-7 rounded-full border flex items-center justify-center">
                                Custom
                              </span>
                            )}
                            {item.drawer && (
                              <span
                                title="Product has drawer."
                                className="px-2 bg-white w-auto text-txtBlack text-sm text-center font-bold h-7 rounded-full border flex items-center justify-center">
                                Drawer
                              </span>
                            )}
                            {item.hinge && (
                              <span
                                title={`${
                                  item.hinge === 'L'
                                    ? 'Left hinging'
                                    : 'Right hinging'
                                }`}
                                className=" w-7 text-txtBlack text-center font-bold h-7 rounded-full border flex items-center justify-center">
                                {item.hinge}
                              </span>
                            )}
                          </div>
                        )}
                        <div className=" min-w-6rem w-28">
                          <h3
                            className={
                              CheckoutProductStyle['attributes'] +
                              ' text-left tablet:text-center '
                            }>
                            $ {(item.price * item.qty).toFixed(2)}
                          </h3>
                        </div>
                        <div
                          className={
                            CheckoutProductStyle['attributes'] +
                            ' tablet:absolute left-0 w-auto tablet:w-9 text-center'
                          }>
                          {item.qty}x
                        </div>
                      </div>
                    </div>
                  ) : null
                })}
              </Collapse>
            </div>
          )
        })}
        <div className="flex flex-col  items-center mt-12">
          <div className="flex w-full justify-between">
            <h2 className="text-txtGray text-lg">Subtotal</h2>
            <div className="font-bold text-txtBlack text-lg">
              $ {subtotal?.toFixed(2)}
            </div>
          </div>
          {isTouchUpKit && (
            <div className="flex w-full justify-between mt-2">
              <h2 className="text-txtGray text-lg">TouchUpKit</h2>
              <div className="font-bold text-txtBlack text-lg">
                $ {dashboardVariables.touchUpKit}
              </div>
            </div>
          )}
          {assemblePrice > 0 && isAssemble && (
            <div className="flex w-full justify-between mt-2">
              <h2 className="text-txtGray text-lg">Assemble fee</h2>
              <div className="font-bold text-txtBlack text-lg">
                $ {assemblePrice.toFixed(2)}
              </div>
            </div>
          )}
        </div>
        {isTaxable && (
          <div className="flex w-full justify-between mt-2">
            <h2 className="text-txtGray text-lg">Sales Tax</h2>
            <div className="font-bold text-txtBlack text-lg">
              {(totalPrice * (salesTax / (100 + salesTax))).toFixed(2)} $
            </div>
          </div>
        )}
        {subtotal !== totalPrice && (
          <div className="flex justify-between items-center mt-12">
            <h2 className="text-txtGray">Total</h2>
            <div className="font-bold text-txtBlack text-xl">
              $ {totalPrice.toFixed(2)}
            </div>
          </div>
        )}
      </div>
      {isEditing && (
        <Modal
          content={'edit'}
          title="Edit order"
          toggle={toggleEdit}
          modalVisible={isEditing}
          products={order.currentOrder}
          setProducts={order.updateOrder}
          handleClick={order.addToOrder}
          index={index}
        />
      )}
    </div>
  )
}

export default CheckoutOrderList
