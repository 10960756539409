import React, { useContext, useEffect, useState } from 'react'
import { db } from '../../services/firebaseConfig'
import { AuthContext } from '../../services/context/authContext/AuthContext'
import { myAccountStyle } from '../../theme/theme'

const defaultUser = {
  firstname: '',
  lastname: '',
  email: '',
  company: ''
}
interface IUser {
  firstname: string
  lastname: string
  email: string
  company: string
}
const MyAccount = () => {
  const user = useContext(AuthContext)
  const [data, setData] = useState<IUser>(defaultUser)
  useEffect(() => {
    db.collection('users')
      .doc(user?.user_id)
      .get()
      .then(doc => {
        setData(doc.data() as IUser)
      })
  }, [user?.user_id])

  return (
    <div className="px-5 flex flex-col  tablet:px-10 desktop:px-20">
      <h2 className="mb-10">Account details</h2>
      <div className={myAccountStyle['whiteContainer']}>
        <div className={myAccountStyle['titlesContainer']}>
          <h2 className={myAccountStyle['titleGray']}>Name</h2>
          <h2 className={myAccountStyle['titleBlack']}>
            {data.firstname + ' ' + data.lastname}
          </h2>
        </div>
        <div className={myAccountStyle['line']} />
        <div className={myAccountStyle['titlesContainer']}>
          <h2 className={myAccountStyle['titleGray']}>Email</h2>
          <h2 className={myAccountStyle['titleBlack']}>{data.email}</h2>
        </div>
        <div className={myAccountStyle['line']} />
        <div className={myAccountStyle['titlesContainer']}>
          <h2 className={myAccountStyle['titleGray']}>Company</h2>
          <h2 className={myAccountStyle['titleBlack']}>{data.company}</h2>
        </div>
      </div>
    </div>
  )
}

export default MyAccount
