import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthContext } from '../../services/context/authContext/AuthContext'

const PrivateRoute = ({ component: RouteComponent, ...rest }: any) => {
  const user = useContext(AuthContext)
  const isAdmin = user?.role === 'admin'
  return (
    <Route
      {...rest}
      render={routeProps => {
        if (isAdmin) {
          return <RouteComponent {...routeProps} />
        } else if (user && !isAdmin) {
          return <Redirect to={'/'} />
        } else return <Redirect to={'/login'} />
      }}
    />
  )
}

export default PrivateRoute
