import React, { ChangeEvent, useState } from 'react'
import { UseFormRegister } from 'react-hook-form'
import { eyeOffOutline, eyeOutline } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'

interface IInput extends React.HTMLProps<HTMLInputElement> {
  label?: string
  classOverride?: boolean
  name?: string
  type?: string
  value?: string
  placeholder?: string
  autoComplete?: string
  required?: boolean
  onChange?(e: ChangeEvent<HTMLInputElement>): any
  register?: UseFormRegister<any>
  options?: {}
  errors?: any
  icon?: boolean
}
const Input = (props: IInput) => {
  const {
    name,
    type,
    placeholder,
    autoComplete,
    value,
    options,
    onChange,
    register,
    errors,
    classOverride,
    className,
    required,
    icon
  } = props

  const [passType, setPassType] = useState('password')
  const togglePasswordVisible = () => {
    setPassType(passType === 'password' ? 'text' : 'password')
  }
  const defaultClass =
    'relative focus:outline-none h-14 px-5 w-80 object-contain bg-inputBg text-black font-medium text-sm rounded-2xl '
  const inputOptions =
    register && options && errors && name
      ? { ...register(name, options ? options : {}) }
      : null
  return (
    <>
      <input
        required={required}
        className={classOverride ? className : defaultClass + ' ' + className}
        type={type === 'password' ? passType : type}
        style={{
          border: name && errors && errors[name] ? '0.5px solid #d94436' : ''
        }}
        value={value}
        name={name}
        {...inputOptions}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onChange={onChange}
      />
      {name && errors && errors[name] ? (
        <div className=" text-xs font-medium tablet:text-sm w-full pl-4 text-red-600">
          {errors[name].message}
        </div>
      ) : null}
      {icon && (
        <IonIcon
          onClick={togglePasswordVisible}
          className="absolute cursor-pointer right-4 text-txtGray w-6 h-6"
          icon={passType === 'password' ? eyeOffOutline : eyeOutline}
        />
      )}
    </>
  )
}

export default Input
