const loginStyles = {
  background:
    'flex flex-col bg-background bg-no-repeat bg-center bg-cover overflow-y-auto absolute top-0 bottom-auto\n' +
    ' min-h-full h-auto right-0 left-0  justify-center items-center',
  cardContainer:
    'bg-white min-w-min  w-2/3 tablet:w-auto  mt-40 mb-40 \n' +
    '      shadow-containerShadow items-center justify-center px-4 tablet:px-20 py-20 rounded-32',
  formContainer: 'flex justify-center'
}
const menuStyles = {
  menuShow:
    'fixed z-50  left-0  tablet:left-auto tablet:right-0 top-0  pl-10 tablet:pl-24  bg-white h-screen overflow-y-auto transform  duration-500 w-72 -translate-x-72 tablet:translate-x-96 ease-out  tablet:w-96 ',
  menuHide:
    'fixed z-50 left-0 tablet:left-auto tablet:right-0 top-0  pl-10 tablet:pl-24  bg-white h-screen overflow-y-auto transform  duration-500 translate-x-0 w-72 ease-out tablet:w-96 ',
  orderShow:
    'fixed z-50 right-0 top-0 py-14  bg-white h-screen overflow-y-auto transform transition duration-500 translate-x-full mobile620px:w-39rem w-full  ease-out max-w-screen-tablet  xl:w-5/12 ',
  orderHide:
    'fixed z-50 right-0 top-0 py-14 bg-white h-screen overflow-y-auto transform transition duration-500 translate-x-0 mobile620px:w-39rem w-full  ease-out max-w-screen-tablet  xl:w-5/12 '
}
const modalStyles = {
  overlayShow:
    ' bg-overlay h-screen w-full z-40 overflow-y-auto py-32 fixed top-0 right-0  flex items-center justify-center ',
  overlayHide:
    ' bg-overlay h-screen hidden w-full z-40 overflow-y-auto fixed py-32 top-0 right-0 flex items-center justify-center',
  titleGray: 'text-txtGray  text-lg tablet:text-xl text-left font-bold ',
  values:
    'flex mt-2 tablet:mt-5  h-auto  items-center text-txtBlack font-bold text-xl tablet:text-xl'
}
const chooseStyles = {
  whiteContainer:
    'flex flex-wrap bg-none gap-4% mobile560px:gap-8 tablet:flex-nowrap mt-10 ',
  containerItem:
    'flex flex-col mt-3 mobile425px:mt-4 mobile560px:mt-0 py-5 tablet:py-0 rounded-2xl tablet:mt-4 bg-white w-48% mobile560px:w-56  tablet:w-56 mobile620px:h-75 ' +
    'justify-center items-center  desktop:w-68  tablet:bg:none  cursor-pointer',

  img: 'w-24 mobile560px:w-32 tablet:mx-auto shadow-styleShadow',
  hType:
    ' text-sm  mt-5 font-bold tablet:text-lg  w-30 mobile560px:w-full  mobile560px:text-auto  '
}
const searchCategories = {
  sizeSelected:
    'cursor-pointer border pl-5 py-4 border-btnStart border border-r-rem95 flex items-center  min-h-5 justify-center h-auto text-lg tablet:text-xl ' +
    'font-medium text-txtBlack  border border-transparent bg-white shadow-containerShadow  rounded-2xl text-center',
  size:
    'cursor-pointer border-transparent pl-5 py-4 border border-r-rem95 flex items-center justify-center min-h-5 h-auto text-lg tablet:text-xl ' +
    'font-medium text-txtBlack bg-white shadow-containerShadow rounded-2xl text-center'
}
const breadCrumbStyles = {
  defaultClass:
    'text-txtGray capitalize inline leading-7 tablet:leading-normal text-lg tablet:text-xl block font-medium cursor-pointer',
  lastItemClass:
    'text-txtBlack capitalize inline leading-7 tablet:leading-normal  text-lg  tablet:text-xl block font-medium cursor-pointer'
}
const productStyle = {
  product:
    'w-full flex px-4 py-6  max-w-screen-2xl  relative transition duration-500 ease-in-out desktop:px-9 desktop:py-8 ' +
    'rounded-2xl bg-white shadow-containerShadow border-transparent tablet:border-r-rem hover:border-btnEnd  cursor-pointer',
  flexItem: 'flex flex-col items-center flex-auto px-4',
  flexItemLeft:
    'flex flex-col text-left items-start flex-auto px-4 tablet:w-1/3 desktop:w-1/4',
  grayTitle:
    ' text-lg desktop:text-xl text-txtGray font-normal hidden tablet:block',
  grayTitleSku:
    ' mb-2 tablet:mb-auto text-lg desktop:text-xl text-txtGray font-normal ',
  attributes:
    'text-lg  w-full tablet:w-auto desktop:text-titleSize text-txtBlack font-bold',
  attributesHidden:
    'hidden tablet:block text-lg tablet:text-titleSize text-txtBlack font-bold',
  description:
    ' mt-2 mb-2 tablet:mb-auto tablet:mt-auto text-sm font-medium desktop:text-base',
  diagonalLine:
    'w-0 hidden h-full border-r border-txtGray  tablet:block transform rotate-10'
}
const CheckoutProductStyle = {
  grayTitle: 'text-txtGray text-lg  font-normal',
  attributes: 'text-txtBlack text-lg font-bold',
  description: 'text-sm text-txtBlack font-medium tablet:text-base',
  accordionExpanded:
    'relative overflow-hidden  transform  transition-all duration-700',
  accordionClosed:
    'relative overflow-hidden   transform  transition-all duration-700'
}
const orderStyle = {
  titlesGray: ' text-txtGray capitalize font-normal',
  titleBlack: 'text-left text-txtBlack capitalize font-bold',
  values: 'text-txtBlack text-center font-bold tablet:mt-2',
  description: ' text-sm font-medium text-txtBlack tablet:text-base mt-2',
  product:
    'border flex justify-items-stretch cursor-pointer px-6 py-6 tablet:py-8 bg-white rounded-2xl z-20 my-5 w-full transition transform duration-500',
  productSelected:
    'border border-btnEnd cursor-pointer bg-white flex  px-6 py-6 tablet:py-8  justify-items-stretch ' +
    ' rounded-2xl my-5 z-20 w-full transition transform duration-500 -translate-x-12 tablet:-translate-x-5',
  close:
    'font-bold stroke-current absolute  fill-current hover:text-red-700 text-txtBlack cursor-pointer right-8 top-16'
}
const sortListComponent = {
  sortItem:
    'py-2 px-4 desktop:py-4 desktop:px-7 cursor-pointer text-base desktop:text-xl transform transition rounded-full ' +
    'select-none w-auto border border-black font-medium text-center text-txtBlack ',
  sortItemSelected:
    'py-2 px-4 desktop:py-4 desktop:px-7 rounded-full cursor-pointer text-base desktop:text-xl transform transition ' +
    ' select-none bg-primary border border-primary font-medium text-center text-white'
}
const usersStyle = {
  titleGray:
    ' text-lg desktop:text-xl text-txtGray font-bold hidden tablet:block',
  titleBlack:
    ' mb-2 tablet:mb-auto text-lg desktop:text-xl text-txtBlack font-bold ',
  thContainer:
    ' w-full hidden desktop:flex  desktop:text-xl xl:text-titleSize  p-8  text-center items-center justify-between ',
  thElement: ' desktop:text-lg xl:text-titleSize text-center ',
  userContainer:
    'desktop:w-full gap-4 relative  border-2 border-transparent desktop:gap-0 relative cursor-pointer flex flex-wrap desktop:flex-nowrap  items-start desktop:flex-row desktop:justify-between ' +
    'desktop:text-center p-5 desktop:p-7  desktop:items-center xl:p-8 bg-white rounded-2xl',
  userContainerEdit:
    'desktop:w-full gap-4  border-2 relative border-primary desktop:gap-0 relative cursor-pointer flex flex-wrap desktop:flex-nowrap items-start desktop:flex-row desktop:justify-between ' +
    'desktop:text-center p-5 desktop:p-7  desktop:items-center p-5 xl:p-8 bg-white rounded-2xl',
  itemElement:
    'text-txtBlack desktop:text-center font-bold text-base desktop:text-lg xl:text-titleSize break-words  px-1 ',
  accountant:
    'h-10 tablet:h-12 text-sm xl:text-base  focus:outline-none font-bold ' +
    'rounded-lg text-center flex items-center justify-center w-full bg-primary text-white',
  accountantEdit:
    'h-10 tablet:h-12 text-center text-sm desktop:text-sm xl:text-base focus:outline-none font-bold' +
    ' rounded-lg text-center flex items-center justify-center w-full border-2 border-primary text-primary',
  collaborator:
    'h-10 tablet:h-12 text-sm xl:text-base text-center focus:outline-none font-bold  rounded-lg text-center flex items-center ' +
    'justify-center w-full bg-primaryYellow text-white',
  collaboratorEdit:
    'h-10 tablet:h-12 text-sm xl:text-base text-center focus:outline-none font-bold' +
    ' rounded-lg text-center flex items-center justify-center w-full border-2 border-primaryYellow text-primaryYellow',
  employee:
    'h-10 tablet:h-12 text-sm xl:text-base  focus:outline-none font-bold  rounded-lg text-center flex items-center justify-center w-full bg-primary text-white',
  employeeEdit:
    'h-10 tablet:h-12 text-center text-sm desktop:text-sm xl:text-base focus:outline-none font-bold  rounded-lg text-center flex items-center justify-center w-full border-2 border-primary text-primary',
  statusConfirmed:
    'h-10 tablet:h-12  text-sm xl:text-base font-bold text-white rounded-lg text-center flex items-center justify-center w-full bg-success',
  statusPending:
    'h-10 tablet:h-12 text-sm xl:text-base font-bold text-white rounded-lg text-center flex items-center justify-center w-full bg-danger',
  discount:
    'h-10 tablet:h-12 text-sm xl:text-base focus:outline-none font-bold  rounded-lg text-center flex items-center justify-center w-full text-white bg-primaryBlue',
  discountEdit:
    'h-10 tablet:h-12 text-sm xl:text-base focus:outline-none font-bold rounded-lg text-center flex items-center justify-center w-full border-2 border-primaryBlue text-primaryBlue',
  valueDisabled:
    'h-10 tablet:h-12 text-sm xl:text-base rounded-lg text-center flex items-center justify-center w-full bg-txtGray text-white '
}
const orderHistory = {
  listItemContainer:
    'w-full gap-3 tablet:gap-0 relative cursor-pointer flex-col flex items-start tablet:flex-row' +
    ' tablet:justify-between text-center pb-8 tablet:pb-5 xl:pb-8 tablet:items-center p-5 xl:p-8 bg-white rounded-2xl',
  listItem:
    'text-txtBlack desktop:text-center font-bold text-base desktop:text-lg xl:text-titleSize break-words  px-1',
  listItemNr:
    'text-txtGray tablet:text-txtBlack desktop:text-center font-bold text-base tablet:text-sm desktop:text-lg xl:text-titleSize break-words  px-1',
  titleGray:
    ' text-lg desktop:text-xl text-txtGray font-bold hidden tablet:block',
  titleBlack:
    ' mb-2 tablet:mb-auto text-lg desktop:text-xl text-txtBlack font-bold ',
  itemPaid:
    'flex relative items-center justify-center  tablet:static w-20 xl:w-32 rounded-md px-2 h-10 font-bold xl:text-lg text-sm text-success border-2 border-success',
  itemNotPaid:
    ' flex relative items-center justify-center  tablet:static w-20 xl:w-32 rounded-md px-2 h-10 font-bold xl:text-lg text-sm text-danger border-2 border-danger'
}
const checkoutStyle = {
  checkboxTitles: ' pl-5 font-medium text-txtBlack capitalize text-sm',
  checkboxDiv: 'flex justify-start items-center',
  textInput:
    'w-full border border-transparent mt-5 px-4 text-sm text-txtBlack tablet:text-base font-normal focus:outline-none focus:border rounded-2xl h-14 bg-inputBg2',
  containerWhite:
    'flex px-7 py-6 w-auto max-w-lg desktop:w-auto bg-white shadow-containerShadow rounded-2xl relative '
}
const styleDescription = {
  image: '  desktop:max-w-30rem h-auto object-cover rounded-2xl',
  paragraph: 'text-xs tablet:text-lg',
  subtitle: ' mb-5 tablet:mb-8 text-left',
  title: 'text-lg py-8 tablet:text-titleSize block',
  textContainer: 'px-5 py-8 desktop:px-10 xl:px-20 tablet:py-10',
  whiteContainer:
    ' inline-flex mt-10 flex-col desktop:flex desktop:flex-row bg-white rounded-2xl'
}
const toasterStyle = {
  container:
    'mx-5 z-50 relative  my-auto w-11/12  tablet:mx-auto max-w-30rem desktop:w-30rem  flex flex-col bg-white rounded-32',
  titleContainer:
    'h-24 rounded-t-32 min-h-6rem  px-14 bg-primary flex flex-col items-start justify-center relative',
  title: 'font-bold text-lg desktop:text-2xl text-white',
  text: 'pb-12 text-center leading-10 text-lg tablet:text-titleSize font-bold',
  deleteBtn:
    'max-w-10 transform active:scale-95 cursor-pointer bg-transparent text-sm min-h-button-h ' +
    'tablet:text-base border-2 border-danger text-danger focus:outline-none active:scale-95 font-bold w-60  h-14  rounded-2xl',
  cancelBtn:
    'max-w-10 transform active:scale-95 cursor-pointer bg-transparent text-sm min-h-button-h tablet:text-base border-2 border-primary text-primary' +
    ' focus:outline-none active:scale-95 font-bold w-60  h-14  rounded-2xl'
}
const myAccountStyle = {
  whiteContainer:
    'flex flex-col  tablet:flex-row p-12 tablet:p-5 gap-8 tablet:gap-0 justify-around  bg-white rounded-2xl',
  line: ' h-0 border-b tablet:w-0 tablet:h-auto  tablet:border-r border-gray-300  transform tablet:rotate-10',
  titlesContainer:
    'flex flex-col gap-3 desktop:gap-5  px-2 py-2 tablet:py-8 items-center justify-center',
  titleGray:
    'text-lg  text-center tablet:text-base desktop:text-xl xl:text-titleSize font-medium text-txtGray',
  titleBlack:
    'text-lg  text-center tablet:text-base desktop:text-xl xl:text-titleSize font-bold text-txtBlack'
}
const pagination = {
  paginationContainer: 'mt-10 flex w-full justify-center',
  paginationItem:
    'p-2 cursor-pointer bg-paginationItemBg text-pagTextGr text-center mx-2 flex items-center justify-center rounded-full w-9 h-9',
  paginationItemSelected:
    'p-2 cursor-pointer bg-paginationItemSelectedBg text-primary text-center mx-2 flex items-center justify-center rounded-full w-9 h-9',
  arrowContainerDisabled:
    'flex items-center justify-center   rounded-xl w-9  pointer-events-none',
  arrowContainer:
    'flex items-center cursor-pointer justify-center rounded-xl w-9 ',
  arrowDisabled: ' text-pagTextGr pointer-events-none',
  arrow: 'text-primary',
  dots: 'text-txtGray',
  showMore:
    'flex justify-center w-40 h-14 items-center rounded-full cursor-pointer text-base desktop:text-xl transform transition' +
    ' select-none bg-primary border border-primary font-medium text-center text-white',
  showMoreContainer: 'flex w-full justify-center my-10'
}
export {
  toasterStyle,
  myAccountStyle,
  usersStyle,
  sortListComponent,
  pagination,
  loginStyles,
  menuStyles,
  modalStyles,
  chooseStyles,
  breadCrumbStyles,
  productStyle,
  orderStyle,
  checkoutStyle,
  searchCategories,
  CheckoutProductStyle,
  orderHistory,
  styleDescription
}
