import React from 'react'
import { ReactComponent as Close } from '../../assets/images/svg/close.svg'

interface ICloseButton {
  onClick: () => void
  className: string
  currentColor: string
}
const CloseButton = (props: ICloseButton) => {
  const { onClick, className, currentColor } = props
  return (
    <Close
      className={` font-bold transform active:scale-90 text-${currentColor} stroke-current absolute fill-current hover:text-red-800 transition duration-200 cursor-pointer ${className}`}
      onClick={onClick}
    />
  )
}

export default CloseButton
