import React, { ChangeEvent } from 'react'
import Input from '../input/Input'
import { ReactComponent as Loupe } from '../../assets/images/svg/loupe.svg'

interface ISearchBar {
  keyword: string
  setKeyword(e: any): any
}

export const SearchBar = (props: ISearchBar) => {
  const { keyword, setKeyword } = props
  return (
    <div className=" flex items-center self-start	 relative mt rounded flex w-full tablet:w-8/12">
      <Input
        type="text"
        classOverride={true}
        placeholder="Search..."
        className="px-14 w-full bg-white placeholder-txtGray focus:outline-none h-14 object-contain rounded-2xl shadow-containerShadow"
        value={keyword}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setKeyword(e.target.value)
        }
      />
      <Loupe className="absolute left-5" />
    </div>
  )
}
