import React, { ChangeEvent, useEffect, useState } from 'react'
import { ReactComponent as Checkmark } from '../../../assets/images/svg/checkmark.svg'
import { modalStyles } from '../../../theme/theme'
import CustomNumberInput from '../../customFormElements/customNumberInput/CustomNumberInput'
import CustomDropdownInput from '../../customFormElements/customDropdownInput/CustomDropdownInput'
import Button from '../../button/Button'
import CustomCheckbox from '../../customFormElements/customCheckbox/CustomCheckbox'
import Input from '../../input/Input'

type Product = {
  description: string
  hasHinging: boolean
  isCustomizable: boolean
  hasDrawerOption: boolean
}
interface IEditableContent {
  products: Product[]
  index: number
  toggle: () => void
  setProducts(products: any[]): void
  handleClick(product: {}, type: 'add' | 'edit'): any
  setTitle: React.Dispatch<React.SetStateAction<string>>
  title?: string
}
const EditableContent = (props: IEditableContent) => {
  const { products, index, toggle, handleClick, setTitle, title } = props
  const [showMessage, setShowMessage] = useState(false)
  const productItem = products[index]
  const [productsCopy, setProductsCopy] = useState(
    JSON.parse(JSON.stringify(products))
  )
  const handleUpdateOrAddProduct = (product: {}) => {
    title === 'Edit order'
      ? handleClick(product, 'edit')
      : handleClick(product, 'add')
  }
  const handleConfirm = () => {
    const product = { ...productsCopy[index] }
    if (product.hasHinging || product.hasDrawerOption) {
      if (product.hasHinging && product.hasDrawerOption) {
        if (product.hinge && product.drawer) {
          handleUpdateOrAddProduct(product)
          setMessage()
        } else alert('Select hinge and drawer option!')
      } else if (product.hasHinging && !product.hasDrawerOption) {
        if (product.hinge) {
          handleUpdateOrAddProduct(product)
          setMessage()
        } else {
          alert('Select hinging!')
        }
      } else if (!product.hasHinging && product.hasDrawerOption) {
        if (product.drawer) {
          handleUpdateOrAddProduct(product)
          setMessage()
        } else {
          alert('Select drawer option!')
        }
      }
    } else {
      handleUpdateOrAddProduct(product)
      setMessage()
    }
  }
  useEffect(() => {
    if (productsCopy[index] && !productsCopy[index].qty) {
      const newList = [...productsCopy]
      const product = { ...newList[index] }
      product.qty = 1
      newList[index].qty = product.qty
      setProductsCopy(newList)
    }
  }, [index, productsCopy, setProductsCopy])
  useEffect(() => {
    setTitle('Add item to your order')
  }, [setTitle])

  const isCustomProd = productsCopy[index].isCustom
  useEffect(() => {
    if (isCustomProd === false) {
      const initialDescription = products[index].description
      const newList = [...productsCopy]
      const product = { ...newList[index] }
      product.description = initialDescription
      newList[index].description = product.description
      setProductsCopy(newList)
    }
  }, [isCustomProd, products, index, productsCopy])

  const setMessage = () => {
    setShowMessage(true)
    setTimeout(() => {
      setShowMessage(false)
      toggle()
    }, 900)
  }
  const handleCheckBoxClick = (name: string) => {
    const newList = [...productsCopy]
    const product = { ...newList[index] }
    if (product[name] === false) {
      product[name] = true
    } else product[name] = !product[name]

    newList[index][name] = product[name]
    setProductsCopy(newList)
  }
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value
    const newList = [...productsCopy]
    const product = { ...newList[index] }
    product[name] = value
    newList[index][name] = product[name]
    setProductsCopy(newList)
  }
  return showMessage ? (
    <div className="flex flex-auto transition-all  opacity-100 duration-300 flex-col w-full items-center justify-center items-center">
      <Checkmark className="w-20 h-20 tablet:h-auto tablet:w-auto" />
      <h2 className="text-lg px-5 text-center mt-4 tablet:text-titleSize tablet:mt-12">
        {title === 'Edit order'
          ? 'The product has been successfully modified.'
          : 'This product has been added to your order!'}
      </h2>
    </div>
  ) : (
    <div className=" px-7 tablet:px-14 tablet:h-full py-12 tablet:py-16 flex flex-auto flex-col justify-stretch items-center">
      <div className="flex flex-col h-full flex-auto  w-full ">
        <div className="flex flex-col w-full ">
          <h4 className="  text-txtGray text-xl text-left font-bold ">Item</h4>
          {productsCopy[index] && (
            <h3 className="min-h-3.5rem flex flex-wrap gap-4 items-center text-txtBlack text-lg tablet:text-xl font-bold text-left">
              {productsCopy[index].name}
              {productsCopy[index].isCustom ? (
                <Input
                  classOverride={true}
                  className="relative focus:outline-none h-14 px-5 w-80 object-contain border  text-sm rounded-2xl  font-bold w-full  mobile620px:w-80"
                  value={productsCopy[index].description}
                  name={'description'}
                  onChange={e => handleInputChange(e)}
                />
              ) : (
                ' - ' + productsCopy[index].description
              )}
            </h3>
          )}
          <hr className="my-6 tablet:my-10" />
        </div>
        <div className="flex flex-col mobile620px:flex-row w-full">
          <div className="flex w-full flex-row tablet:w-1/2 ">
            <div className=" w-full tablet:w-1/2 flex flex-col items-center mobile620px:items-start ">
              <h4 className={modalStyles['titleGray']}>SKU</h4>
              <div className={modalStyles['values']}>
                {productsCopy[index] && productsCopy[index].sku}
              </div>
            </div>
            <div className=" w-full tablet:w-1/2 flex flex-col items-center mobile620px:items-start">
              <h4 className={modalStyles['titleGray']}>Price</h4>
              <div className={modalStyles['values']}>
                ${productsCopy[index] && productsCopy[index].price.toFixed(2)}
              </div>
            </div>
          </div>
          <hr className=" block my-4 tablet:hidden" />
          <div className="w-full flex tablet:w-1/2 ">
            <div className=" w-full tablet:w-1/2 flex flex-col items-center mobile620px:items-start">
              <h4 className={modalStyles['titleGray']}>Quantity</h4>
              <div className={modalStyles['values']}>
                <CustomNumberInput
                  value={productsCopy[index] && productsCopy[index].qty}
                  index={index}
                  setOrder={setProductsCopy}
                  products={productsCopy}
                  min={1}
                  max={100}
                />
              </div>
            </div>

            {productItem.hasHinging && (
              <div className=" w-full tablet:w-1/2 flex flex-col items-center mobile620px:items-start">
                <h4 className={modalStyles['titleGray']}>Hinging</h4>
                <div className={modalStyles['values']}>
                  <CustomDropdownInput
                    name="hinge"
                    value={productsCopy[index] && productsCopy[index].hinge}
                    products={productsCopy}
                    index={index}
                    setProducts={setProductsCopy}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex mt-10 flex-row items-end mobile620px:flex-row w-full">
          {productItem.isCustomizable && (
            <div
              className={`flex ${
                productItem.hasDrawerOption ? ' w-1/2 ' : ' w-full '
              } flex-col items-center gap-1 mobile620px:flex-row mobile620px:w-3/4 
             mobile620px:gap-3 flex justify-center mobile620px:justify-start mobile620px:items-start`}>
              <CustomCheckbox
                checked={productsCopy[index].isCustom || false}
                name="isCustom"
                onClick={name => handleCheckBoxClick(name)}
              />
              <span className="font-bold text-center text-xs mobile620px:text-sm mr-2 h-8">
                I want custom sizes and specifications
              </span>
            </div>
          )}

          {productItem.hasDrawerOption && (
            <div className="w-1/2 mobile620px:w-1/4 ">
              <div className="w-full flex flex-col items-center mobile620px:items-start">
                <h4 className={modalStyles['titleGray']}>Drawers</h4>
                <div className={modalStyles['values']}>
                  <CustomDropdownInput
                    name="drawer"
                    value={productsCopy[index] && productsCopy[index].drawer}
                    products={productsCopy}
                    index={index}
                    setProducts={setProductsCopy}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Button
        title="Confirm"
        className="mt-10"
        onClick={() => {
          handleConfirm()
        }}
      />
    </div>
  )
}

export default EditableContent
