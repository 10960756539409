import React, { useContext, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AuthContext } from '../../services/context/authContext/AuthContext'
import { ReactComponent as MenuIcon } from '../../assets/images/svg/menu.svg'
import Delivery from '../deliveryIcon/Delivery'
import DrawerMenu from '../drawerMenu/DrawerMenu'
import OrderDrawer from '../orderDrawer/OrderDrawer'

const NavBar = () => {
  const userContext = useContext(AuthContext)
  const user = userContext?.role

  const [showMenu, setShowMenu] = useState<boolean>(true)
  const [showOrder, setShowOrder] = useState<boolean>(true)

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }
  const toggleOrder = () => {
    setShowOrder(!showOrder)
  }
  const location = useLocation().pathname
  const helpStyle =
    location === '/login' ||
    location === '/signup' ||
    location === 'forgot-password'
      ? 'text-white'
      : 'text-txtBlack'
  const logoSource =
    location === '/login' ||
    location === '/signup' ||
    location === 'forgot-password'
      ? require('../../assets/images/png/white-logo.png').default
      : require('../../assets/images/png/logo.png').default

  return (
    <div className="">
      <div
        className={`flex ${
          !user ? 'static py-16' : 'relative py-11'
        } px-5  z-10 tablet:px-24 tablet:py-20 flex-row justify-center items-center tablet:justify-between`}>
        <Link
          to="/"
          className="flex flex-row items-center z-10 absolute tablet:static">
          {user ? (
            <img
              className="w-32 tablet:w-44"
              alt="logo"
              src={require('../../assets/images/png/logo.png').default}
            />
          ) : (
            <img className="w-56  tablet:w-44" alt="logo" src={logoSource} />
          )}
        </Link>
        {user ? (
          <div className="flex gap-11 tablet:w-auto tablet:flex-row flex-row-reverse w-full justify-between items-center tablet:justify-between">
            <Delivery toggle={toggleOrder} />
            <MenuIcon className="cursor-pointer" onClick={toggleMenu} />
          </div>
        ) : (
          <div className=" hidden tablet:flex items-center z-10 text-lg bottom-0 gap-5 font-bold">
            <Link to="/contact">
              <h2 className={helpStyle}>Contact</h2>
            </Link>
            <Link to="/help">
              <h2 className={helpStyle}>Help</h2>
            </Link>
          </div>
        )}
      </div>
      {user && <DrawerMenu showMenu={showMenu} toggle={toggleMenu} />}
      {user && <OrderDrawer showOrder={showOrder} toggle={toggleOrder} />}
    </div>
  )
}

export default NavBar
