import React from 'react'
import { loginStyles } from '../../theme/theme'
import MobileFooter from '../../components/mobileFooter/MobileFooter'
import ForgotPasswordForm from '../../components/forms/forgotPasswordForm/ForgotPasswordForm'

const ForgotPassword = () => {
  return (
    <div className={loginStyles['background']}>
      <div className={loginStyles['cardContainer']}>
        <div className={loginStyles['formContainer']}>
          <ForgotPasswordForm />
        </div>
      </div>
      <MobileFooter />
    </div>
  )
}

export default ForgotPassword
