import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthContext } from '../../services/context/authContext/AuthContext'

const PrivateRoute = ({ component: RouteComponent, ...rest }: any) => {
  const user = useContext(AuthContext)
  return (
    <Route
      {...rest}
      render={routeProps => {
        if (!!user) {
          return <RouteComponent {...routeProps} />
        } else {
          return <Redirect to={'/login'} />
        }
      }}
    />
  )
}

export default PrivateRoute
