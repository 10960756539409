import React, { createContext, useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { auth } from '../../firebaseConfig'
import LoadingScreen from '../../../components/loadingScreen/LoadingScreen'

export const AuthContext =
  createContext<firebase.firestore.DocumentData | null>({})

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<firebase.firestore.DocumentData | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    return auth.onAuthStateChanged(user => {
      if (user) {
        user
          .getIdTokenResult()
          .then(idTokenResult => {
            setUser(idTokenResult.claims)
            setLoading(false)
          })
          .catch(error => {
            console.log('Error getting document:', error)
          })
      } else {
        setUser(null)
        setLoading(false)
      }
    })
  }, [])

  if (loading) {
    return (
      <div>
        <LoadingScreen />
      </div>
    )
  } else {
    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>
  }
}
