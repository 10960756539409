import React from 'react'
import { chooseStyles } from '../../theme/theme'

interface IChooseStyle {
  setStyle(style: string, type: string, img: string): any
}

const ChooseStyle = (props: IChooseStyle) => {
  const { setStyle } = props
  return (
    <div className="mt-16 w-full">
      <h2 className="">Choose style and colour:</h2>
      <div className="flex flex-wrap  gap-20 mt-16 mb-11">
        <div className=" flex flex-col  w-full tablet:w-auto">
          <h2>Shaker Style</h2>
          <div className={chooseStyles['whiteContainer']}>
            <div
              className={chooseStyles['containerItem']}
              onClick={() =>
                setStyle(
                  'Shaker Style',
                  'SE - Shaker Espresso',
                  'SE-ShakerEspresso'
                )
              }>
              <img
                src={
                  require('../../assets/images/png/styles/SE-ShakerEspresso.png')
                    .default
                }
                alt=""
                className={chooseStyles['img']}
              />
              <h3 className={chooseStyles['hType']}>SE - Shaker Espresso</h3>
            </div>
            <div
              className={chooseStyles['containerItem']}
              onClick={() =>
                setStyle('Shaker Style', 'SW - Shaker White', 'SW-ShakerWhite')
              }>
              <img
                src={
                  require('../../assets/images/png/styles/SW-ShakerWhite.png')
                    .default
                }
                alt=""
                className={chooseStyles['img']}
              />
              <h3 className={chooseStyles['hType']}>SW - Shaker White</h3>
            </div>
            <div
              className={chooseStyles['containerItem']}
              onClick={() =>
                setStyle('Shaker Style', 'GR - Shaker Gray', 'GR-ShakerGray')
              }>
              <img
                src={
                  require('../../assets/images/png/styles/GR-ShakerGray.png')
                    .default
                }
                alt=""
                className={chooseStyles['img']}
              />
              <h3 className={chooseStyles['hType']}>GR - Shaker Gray</h3>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full  tablet:w-auto">
          <h2>Frameless Style</h2>
          <div className={chooseStyles['whiteContainer']}>
            <div
              className={chooseStyles['containerItem']}
              onClick={() =>
                setStyle('Frameless Style', 'AE - Autumn Elm', 'AE-AutumnElm')
              }>
              <img
                src={
                  require('../../assets/images/png/styles/AE-AutumnElm.png')
                    .default
                }
                alt=""
                className={chooseStyles['img']}
              />
              <h3 className={chooseStyles['hType']}>AE - Autumn Elm</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="flex  flex-wrap gap-20 mt-16 mb-11">
        <div className="flex flex-col w-full  tablet:w-auto">
          <h2>Charleston Style</h2>
          <div className={chooseStyles['whiteContainer']}>
            <div
              className={chooseStyles['containerItem']}
              onClick={() =>
                setStyle(
                  'Charleston Style',
                  'CS - Charleston Saddle',
                  'CS-CharlestonSaddle'
                )
              }>
              <img
                src={
                  require('../../assets/images/png/styles/CS-CharlestonSaddle.png')
                    .default
                }
                alt=""
                className={chooseStyles['img']}
              />
              <h3 className={chooseStyles['hType']}>CS - Charleston Saddle</h3>
            </div>
            <div
              className={chooseStyles['containerItem']}
              onClick={() =>
                setStyle(
                  'Charleston Style',
                  'CW - Charleston White',
                  'CW-CharlestonWhite'
                )
              }>
              <img
                src={
                  require('../../assets/images/png/styles/CW-CharlestonWhite.png')
                    .default
                }
                alt=""
                className={chooseStyles['img']}
              />
              <h3 className={chooseStyles['hType']}>CW - Charleston White</h3>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full tablet:w-auto">
          <h2>Aspen Style</h2>
          <div className={chooseStyles['whiteContainer']}>
            <div
              className={chooseStyles['containerItem']}
              onClick={() =>
                setStyle(
                  'Aspen Style',
                  'AC - Aspen Charcoal',
                  'AC-AspenCharcoal'
                )
              }>
              <img
                src={
                  require('../../assets/images/png/styles/AC-AspenCharcoal.png')
                    .default
                }
                alt=""
                className={chooseStyles['img']}
              />
              <h3 className={chooseStyles['hType']}>AC - Aspen Charcoal</h3>
            </div>
            <div
              className={chooseStyles['containerItem']}
              onClick={() =>
                setStyle('Aspen Style', 'AW - Aspen White', 'AW-AspenWhite')
              }>
              <img
                src={
                  require('../../assets/images/png/styles/AW-AspenWhite.png')
                    .default
                }
                alt="Aspen"
                className={chooseStyles['img']}
              />
              <h3 className={chooseStyles['hType']}>AW - Aspen White</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChooseStyle
