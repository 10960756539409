import React from 'react'
import { DOTS, usePagination } from './usePagination'
import { pagination } from '../../theme/theme'
import { ReactComponent as Left } from '../../assets/images/svg/left.svg'
import { ReactComponent as Right } from '../../assets/images/svg/right.svg'

interface IPagination {
  onPageChange(page: number | string): void
  totalCount: number
  currentPage: number
  pageSize: number
}

const Pagination = (props: IPagination) => {
  const { onPageChange, totalCount, currentPage, pageSize } = props
  let siblingCount = 1
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  })

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange && paginationRange[paginationRange.length - 1]
  return (
    <ul className={pagination['paginationContainer']}>
      <li
        className={
          currentPage === 1
            ? pagination['arrowContainerDisabled']
            : pagination['arrowContainer']
        }
        onClick={onPrevious}>
        <Left
          className={
            currentPage === 1
              ? pagination['arrowDisabled']
              : pagination['arrow']
          }
        />
      </li>
      {paginationRange &&
        paginationRange.map(pageNumber => {
          if (pageNumber === DOTS) {
            return <li className={pagination['dots']}>&#8230;</li>
          }
          return (
            <li
              key={pageNumber}
              className={
                pageNumber === currentPage
                  ? pagination['paginationItemSelected']
                  : pagination['paginationItem']
              }
              onClick={() => onPageChange(pageNumber)}>
              {pageNumber}
            </li>
          )
        })}
      <li
        className={
          currentPage === lastPage
            ? pagination['arrowContainerDisabled']
            : pagination['arrowContainer']
        }
        onClick={onNext}>
        <Right
          className={
            currentPage === lastPage
              ? pagination['arrowDisabled']
              : pagination['arrow']
          }
        />
      </li>
    </ul>
  )
}

export default Pagination
