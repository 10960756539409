import React, { useContext, useEffect, useState } from 'react'
import UserList from '../../components/listComponents/userList/UserList'
import { SearchBar } from '../../components'
import SortListComponent from '../../components/listComponents/sortListComponent/SortListComponent'
import { AuthContext } from '../../services/context/authContext/AuthContext'
import { db } from '../../services/firebaseConfig'

interface IUser {
  createdAt: any
  id: string
  email: string
  firstname: string
  company: string
  lastname: string
  discount: number
  disabled: boolean
  role: string
}
function Users() {
  const loggedInUser = useContext(AuthContext)
  const userEmail = loggedInUser && loggedInUser.email
  const [userListDefault, setUserListDefault] = useState<IUser[]>([])
  const [users, setUsers] = useState<IUser[]>([])
  const [sortItem, setSortItem] = useState<string>('recent')
  const [isLoading, setIsLoading] = useState(false)
  const [input, setInput] = useState('')
  const sortList = [
    'recent',
    'A-Z',
    'employees',
    'collaborators',
    'confirmed',
    'pending'
  ]

  useEffect(() => {
    const admin = loggedInUser?.role === 'admin'
    const getUsers = () => {
      setIsLoading(true)
      const userRef = db.collection('users')
      const query = !admin
        ? userRef.where('createdBy', '==', userEmail)
        : userRef
      query.onSnapshot(querySnapshot => {
        const userList: IUser[] = []
        querySnapshot.forEach(doc => {
          const userItem = doc.data()
          // doc.data() is never undefined for query doc snapshots
          userList.push(userItem as IUser)
        })
        setUserListDefault(userList)
        setIsLoading(false)
      })
    }
    return getUsers()
  }, [userEmail, loggedInUser?.role])

  useEffect(() => {
    const filter = () => {
      let filteredList = [...userListDefault]
      if (sortItem === 'confirmed') {
        filteredList = userListDefault.filter(user => {
          if (!user.disabled) {
            return user
          }
          return null
        })
        setUsers(filteredList)
      } else if (sortItem === 'pending') {
        filteredList = userListDefault.filter(user => {
          if (user.disabled) {
            return user
          }
          return null
        })
        setUsers(filteredList)
      } else if (sortItem === 'recent') {
        filteredList.sort((a, b) => {
          return b.createdAt.toDate() - a.createdAt.toDate()
        })
        setUsers(filteredList)
      } else if (sortItem === 'A-Z') {
        filteredList.sort((a, b) => {
          return a.email.localeCompare(b.email)
        })
        setUsers(filteredList)
      } else if (sortItem === 'employees') {
        filteredList = userListDefault.filter(user => {
          if (user.role === 'employee') {
            return user
          }
          return null
        })
        setUsers(filteredList)
      } else if (sortItem === 'collaborators') {
        filteredList = userListDefault.filter(user => {
          if (user.role === 'collaborator') {
            return user
          }
          return null
        })
        setUsers(filteredList)
      }
    }
    return filter()
  }, [sortItem, userListDefault])

  const updateInput = async (input: string) => {
    const filtered = userListDefault.filter((user: IUser) => {
      if (
        user.company.toLowerCase().includes(input.toLowerCase()) ||
        user.email.toLowerCase().includes(input.toLowerCase())
      ) {
        return user
      }
      return null
    })
    setInput(input)
    setUsers(filtered)
  }
  return (
    <div>
      <div className="px-5 tablet:px-16 desktop:px-20">
        <h2 className="mb-10">Users</h2>
        <SearchBar keyword={input} setKeyword={updateInput} />
        <div className="flex flex-col mx-4 tablet:mx-0  tablet:flex-row gap-5 mt-16 tablet:items-center ">
          <SortListComponent
            items={sortList}
            item={sortItem}
            setItem={setSortItem}
          />
        </div>
        <UserList users={users} isLoadingList={isLoading} />
      </div>
    </div>
  )
}

export default Users
