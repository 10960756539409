import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
const env = process.env.NODE_ENV
console.log(`Your app is running in ${env} build`)

const firebaseConfig = {
  apiKey: process.env['REACT_APP_FIREBASE_API_KEY'],
  authDomain: process.env['REACT_APP_FIREBASE_AUTH_DOMAIN'],
  // databaseURL: process.env['REACT_APP_FIREBASE_DATABASE_URL'],
  projectId: process.env['REACT_APP_FIREBASE_PROJECT_ID'],
  storageBucket: process.env['REACT_APP_FIREBASE_STORAGE_BUCKET'],
  messagingSenderId: process.env['REACT_APP_FIREBASE_MESSAGING_SENDER_ID'],
  appId: process.env['REACT_APP_FIREBASE_APP_ID'],
  measurementId: process.env['REACT_APP_FIREBASE_MEASUREMENT_ID ']
}
// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}
const auth = firebase.auth()
const db = firebase.firestore()
const app = firebase.app()
const functions = firebase.functions()
export { auth, db, app, functions }
