import React, { useContext, useEffect, useState } from 'react'
import { SearchBar } from '../../components'
import ProductList from '../../components/listComponents/productList/ProductList'
import { db } from '../../services/firebaseConfig'
import ChooseStyle from '../../components/chooseStyle/ChooseStyle'
import Breadcrumb from '../../components/breadCrumb/BreadCrumb'
import SearchCategories from '../../components/searchCategories/SearchCategories'
import { AuthContext } from '../../services/context/authContext/AuthContext'
import StyleDescription from '../../components/styleDescription/StyleDescription'

interface IProduct {
  img: string
  name: string
  category: string
  subcategory: string
  type: string
  description: string
  price: number
  sku: string
}
const chosenValues = {
  style: '',
  type: '',
  category: '',
  subcategory: '',
  img: ''
}
const Homepage = () => {
  const user = useContext(AuthContext)
  const [input, setInput] = useState<string>('')
  const [productListDefault, setProductListDefault] = useState([] as any)
  const [products, setProducts] = useState([] as any)
  const [chosenStyle, setChosenStyle] = useState(chosenValues)
  const [showProducts, setShowProducts] = useState(false)
  useEffect(() => {
    if (chosenStyle.subcategory) {
      return handleConfirmSearch()
    }
  }, [chosenStyle.subcategory])

  useEffect(() => {
    let isMounted = true
    const discount = user?.discount
    const type = chosenStyle.type
    const fetchProducts = async () => {
      const productList: any = []
      const response = db.collection('products').where('type', '==', type)
      const data = await response.get()
      data.docs.forEach(product => {
        const prod = product.data()
        if (discount) {
          prod.price = prod.price - (prod.price * discount) / 100
        }
        productList.push(prod as IProduct)
      })
      if (isMounted) {
        setProducts(productList)
        setProductListDefault(productList)
      }
    }
    fetchProducts().catch(err => console.log(err))
    return () => {
      isMounted = false
    }
  }, [user, chosenStyle.type])

  const updateInput = async (input: string) => {
    setShowProducts(false)
    const filtered = productListDefault
      .filter((product: IProduct) => {
        if (
          product.sku.toLowerCase().includes(input.toLowerCase()) ||
          product.category.toLowerCase().includes(input.toLowerCase()) ||
          product.name.toLowerCase().includes(input.toLowerCase()) ||
          product.subcategory.toLowerCase().includes(input.toLowerCase()) ||
          product.type.toLowerCase().includes(input.toLowerCase())
        ) {
          return product
        }
        return null
      })
      .sort((a: IProduct, b: IProduct) => a.sku.localeCompare(b.sku))
    setInput(input)
    setProducts(filtered)
  }
  const handleConfirmSearch = () => {
    setShowProducts(true)
    const filtered = productListDefault
      .filter((product: IProduct) => {
        if (
          product.category
            .toLowerCase()
            .includes(chosenStyle.category.toLowerCase()) &&
          chosenStyle.subcategory &&
          product.subcategory
            .toLowerCase()
            .includes(chosenStyle.subcategory.toLowerCase()) &&
          product.type.toLowerCase().includes(chosenStyle.type.toLowerCase())
        ) {
          return product
        }
        return null
      })
      .sort((a: IProduct, b: IProduct) => a.sku.localeCompare(b.sku))
    setProducts(filtered)
  }
  const handleBreadcrumbChange = (crumb: any) => {
    if (crumb === chosenStyle.type) {
      setChosenStyle({
        ...chosenStyle,
        category: '',
        subcategory: ''
      })
    } else if (crumb === chosenStyle.category) {
      setChosenStyle({
        ...chosenStyle,
        subcategory: ''
      })
    } else if (crumb === chosenStyle.subcategory || crumb === 'products') {
      setChosenStyle({ ...chosenStyle, subcategory: '' })
    } else {
      setChosenStyle({
        ...chosenStyle,
        style: '',
        type: '',
        category: '',
        subcategory: '',
        img: ''
      })
    }
    setShowProducts(false)
  }

  const updateChosenStyle = (style: string, type: string, img: string) => {
    setChosenStyle({
      ...chosenStyle,
      style: style,
      type: type,
      img: img
    })
  }
  const setCategories = (value: string) => {
    if (chosenStyle.category) {
      setChosenStyle({ ...chosenStyle, subcategory: value })
    } else {
      setChosenStyle({ ...chosenStyle, category: value })
    }
  }

  return (
    <div className=" flex w-full justify-center">
      <div className=" mx-5 tablet:mx-14  w-full my-2 1440px:mx-24 flex flex-col justify-center items-center overflow-visible">
        {chosenStyle.style && (
          <h2 className="my-4 text-2xl w-full  text-left font-bold hidden tablet:block capitalize">
            search
          </h2>
        )}
        {chosenStyle.style && (
          <SearchBar keyword={input} setKeyword={updateInput} />
        )}
        {chosenStyle.type && (
          <Breadcrumb
            category={chosenStyle.category}
            name={chosenStyle.type}
            subcategory={chosenStyle.subcategory}
            showProducts={showProducts}
            onClick={handleBreadcrumbChange}
          />
        )}
        {!chosenStyle.style && !input ? (
          <ChooseStyle setStyle={updateChosenStyle} />
        ) : null}
        <div
          className={`flex w-full flex-col flex-col-reverse tablet:flex-row justify-center mt-10 tablet:mt-14  ${
            (input || showProducts) && 'hidden overflow-hidden'
          }`}>
          {chosenStyle.style && (
            <SearchCategories
              category={chosenStyle.category}
              subcategory={chosenStyle.subcategory}
              setCategories={setCategories}
            />
          )}
          {chosenStyle.style && (
            <div className="flex  w-full justify-between tablet:justify-start flex-row-reverse tablet:flex-col  desktop:flex-row  desktop:w-8/12 xl:w-55 desktop:justify-between tablet:pl-10">
              <div className="flex tablet:flex-col items-center tablet:items-start">
                <h2 className=" hidden desktop:block text-transparent select-none">
                  .
                </h2>
              </div>
              <div className="flex flex-col  tablet:mt-0 ">
                <h2 className="hidden tablet:block">{chosenStyle.style}</h2>
                <div
                  className=" py-4  w-24 justify-center tablet:w-72 tablet:px-16 items-center flex tablet:flex-col tablet:mt-6 tablet:py-9 cursor-pointer border border-btnEnd rounded-2xl bg-white shadow-containerShadow"
                  onClick={() =>
                    setChosenStyle({
                      ...chosenStyle,
                      style: '',
                      type: '',
                      category: '',
                      subcategory: '',
                      img: ''
                    })
                  }>
                  <img
                    className="w-16  tablet:w-32 tablet:mb-auto tablet:mt-auto min-w-img-min-w shadow-styleShadow"
                    src={
                      require(`../../assets/images/png/styles/${chosenStyle.img}.png`)
                        .default
                    }
                    alt=""
                  />
                  <h3 className="absolute tablet:w-auto tablet:mt-7 left-32 tablet:static">
                    {chosenStyle.type}
                  </h3>
                </div>
              </div>
            </div>
          )}
        </div>
        {input ||
        (chosenStyle.category && showProducts && chosenStyle.subcategory) ? (
          products.length ? (
            <ProductList products={products} setProducts={setProducts} />
          ) : (
            <div className="font-bold mt-10">No products found</div>
          )
        ) : null}
        {!chosenStyle.style && <StyleDescription />}
      </div>
    </div>
  )
}

export default Homepage
