import React, { useContext, useEffect, useState } from 'react'
import Button from '../../button/Button'
import { pdf, PDFDownloadLink } from '@react-pdf/renderer'
import PdfContent from '../../pdf/PdfContent'
import { orderHistory } from '../../../theme/theme'
import XLSX from 'xlsx'
import { AuthContext } from '../../../services/context/authContext/AuthContext'
import moment from 'moment'
type Order = {
  id: string
  invoiceId: string
  paymentType: string
  billingAddress: {
    address: string
    city: string
    state: string
    zip: string
  }
  deliveryAddress: {
    address: string
    city: string
    state: string
    zip: string
  }
  company: string
  createdAt: any
  createdBy: string
  deliveryDate: Date | undefined | string
  pickupDate: Date | undefined | string
  isTaxable: boolean
  isTouchUpKit: boolean
  products: any[]
  subtotal: number
  touchUpKit: number
  totalPrice: number
  assembleFee: number
  encodedXlsx: string
}
interface IOrderContent {
  orders: Order[]
  index: number
}

const OrderContent = (props: IOrderContent) => {
  const user = useContext(AuthContext)
  const isAdmin = user?.role === 'admin'
  const isAccountant = user?.role === 'accountant'
  const { orders, index } = props
  const order = orders[index]
  const [pdfIsLoaded, setPdfIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setPdfIsLoaded(true)
  }, [])
  const downloadXlsx = async () => {
    setIsLoading(true)
    const wb = XLSX.read(order.encodedXlsx, { type: 'base64' })
    /* generate XLSX file and send to client */
    await XLSX.writeFile(wb, `${order.invoiceId}.xlsx`)
  }

  const previewPdf = async () => {
    const instance = pdf()
    instance.updateContainer(<PdfContent order={order} />)
    const blob = await instance.toBlob()
    const reader = new window.FileReader()
    reader.readAsArrayBuffer(blob)
    reader.onloadend = function () {
      const arrayBufferData = reader.result && reader.result
      const file =
        arrayBufferData &&
        new Blob([arrayBufferData], { type: 'application/pdf' })
      if (file) {
        const fileUrl = URL.createObjectURL(file)
        window.open(fileUrl, '_blank')
      }
    }
  }
  return (
    <div className="pt-14 pb-24 px-5 tablet:px-10 flex flex-col ">
      {isAdmin && (
        <div className={'flex flex-col gap-6 mb-14'}>
          <div>
            <h2 className={orderHistory['titleGray']}>Date</h2>
            <h2 className={orderHistory['titleBlack']}>
              {moment(order.createdAt.toDate()).format('MMMM-DD-YYYY')}
            </h2>
          </div>
          <div>
            <h2 className={orderHistory['titleGray']}>User</h2>
            <h2 className={orderHistory['titleBlack'] + ' break-words'}>
              {order.createdBy}
            </h2>
          </div>
          <div>
            <h2 className={orderHistory['titleGray']}>Company</h2>
            <h2 className={orderHistory['titleBlack']}>{order.company}</h2>
          </div>
          <div>
            <h2 className={orderHistory['titleGray']}>Price</h2>
            <h2 className={orderHistory['titleBlack']}>
              $ {order.totalPrice.toFixed(2)}
            </h2>
          </div>
        </div>
      )}
      <h2>Download Files</h2>
      <div className="flex flex-col tablet:flex-row mt-6 tablet:mt-10 gap-5">
        <Button
          content={
            pdfIsLoaded ? (
              <PDFDownloadLink
                className={' w-full h-full flex items-center justify-center'}
                document={<PdfContent order={order} />}
                fileName={`${order.invoiceId}.pdf`}>
                {({ loading, error }) => {
                  if (loading) {
                    return ' Loading pdf...'
                  } else if (error) {
                    return 'pdf error'
                  } else {
                    return 'Download Pdf'
                  }
                }}
              </PDFDownloadLink>
            ) : (
              'Loading...'
            )
          }
        />
        <Button
          title={'Preview pdf'}
          onClick={() => {
            previewPdf().catch(err => console.log(err))
          }}
        />
        {(isAdmin || isAccountant) && (
          <Button
            isLoading={isLoading}
            title={'Download Xlsx'}
            onClick={() => downloadXlsx().then(() => setIsLoading(false))}
          />
        )}
      </div>
    </div>
  )
}

export default OrderContent
