import React, { useEffect } from 'react'
import { modalStyles } from '../../../theme/theme'
import SlickCarousel from '../../slickCarousel/SlickCarousel'

interface IDescriptionContent {
  index: number
  products: any[]
  setTitle: React.Dispatch<React.SetStateAction<string>>
}
const DescriptionContent = (props: IDescriptionContent) => {
  const { products, index, setTitle } = props
  useEffect(() => {
    setTitle('Product description')
  }, [setTitle])

  const product = products[index]
  return (
    <div className=" px-7 tablet:px-14 py-14 flex flex-col-reverse tablet:flex-row box-border flex-auto justify-stretch items-center">
      <div className="flex flex-col w-full tablet:w-2/5 h-full">
        <div className="flex w-full tablet:flex-col">
          <div className="flex flex-col w-full border-b py-4 ">
            <div className="h-full flex flex-col  ">
              <h4 className={modalStyles['titleGray']}>SKU</h4>
              <div className={modalStyles['values']}>{product.sku}</div>
            </div>
          </div>
          <div className="flex flex-col w-full border-b py-4">
            <h4 className={modalStyles['titleGray']}>Item name</h4>
            <div className={modalStyles['values']}>{product.name}</div>
          </div>
        </div>
        <div className="flex flex-col w-full border-b py-4">
          <div className="flex">
            <div className="w-full">
              <h4 className={modalStyles['titleGray']}>Description</h4>
              <div className={modalStyles['values']}>
                ${product.description}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full py-4">
          <div className="flex">
            <div className="w-1/2">
              <h4 className={modalStyles['titleGray']}>Weight</h4>
              <div className={modalStyles['values']}>{product.weight}</div>
            </div>
            <div className="w-1/2">
              <h4 className={modalStyles['titleGray']}>Cube</h4>
              <div className={modalStyles['values']}>{product.cubes}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-3 tablet:px-10 h-auto tablet:h-full w-full tablet:w-3/5 flex justify-center">
        <SlickCarousel slides={product.img} />
      </div>
    </div>
  )
}

export default DescriptionContent
