import React, { ChangeEvent, FormEvent, useState } from 'react'
import Button from '../../button/Button'
import Input from '../../input/Input'
import { auth } from '../../../services/firebaseConfig'
import { useHistory } from 'react-router-dom'

const messageType = {
  error: '',
  success: ''
}
const ForgotPasswordForm = () => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState(messageType)
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value
    setEmail(value)
  }
  const handleReset = async (event: FormEvent<HTMLFormElement>) => {
    setIsLoading(true)
    event.preventDefault()
    if (email) {
      await auth
        .sendPasswordResetEmail(email)
        .then(() => {
          setMessage({
            ...message,
            success: `An email to reset your password has been sent to ${email}.`,
            error: ''
          })
          setIsLoading(false)
          setTimeout(() => history.push('/login'), 4000)
        })
        .catch((error: any) => {
          setMessage({
            ...message,
            error: error.message,
            success: ''
          })
          setIsLoading(false)
        })
    }
  }
  return (
    <form className="flex flex-col items-center" onSubmit={e => handleReset(e)}>
      <h2 className="font-bold text-txtBlack text-lg tablet:text-titleSize ">
        Reset your password
      </h2>
      <Input
        className="mt-12"
        type="email"
        onChange={e => handleChange(e)}
        placeholder="Email"
        name="email"
        required={true}
        value={email}
      />
      {message && message.error ? (
        <>
          <span className="text-sm w-80  px-2 text-red-700 mt-5 text-center">
            {message.error}
          </span>
          <span
            className="text-sm mt-5 w-80 cursor-pointer underline text-center font-bold"
            onClick={() => history.push('/login')}>
            login
          </span>
        </>
      ) : (
        <span className="text-sm px-2 w-80 text-green-700 mt-5 text-center">
          {message.success}
        </span>
      )}
      <Button
        isLoading={isLoading}
        className="mt-20"
        title="Reset password"
        type="submit"
      />
    </form>
  )
}

export default ForgotPasswordForm
