import React, { ChangeEvent } from 'react'
import { ReactComponent as Minus } from '../../../assets/images/svg/minus.svg'
import { ReactComponent as Plus } from '../../../assets/images/svg/plus.svg'

interface ICustomNumberInput {
  value: number
  className?: string
  buttonClassName?: string
  min: number
  max: number
  index: number
  products: any[]
  setOrder(product: {}): void
}

const CustomNumberInput = (props: ICustomNumberInput) => {
  const {
    value,
    products,
    index,
    setOrder,
    min,
    max,
    className,
    buttonClassName
  } = props

  const handleQuantity = (action: string) => {
    const newList = [...products]
    const product = { ...newList[index] }
    if (action === 'increase' && product.qty < max) {
      product.qty = product.qty + 1
    }
    if (product.qty > min && action === 'decrease') {
      product.qty = product.qty - 1
    }
    newList[index].qty = product.qty
    setOrder(newList)
  }
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newList = [...products]
    const product = { ...newList[index] }
    const value = parseInt(event.target.value)
    if (value) {
      if (value > 100) {
        product.qty = 100
      } else if (value < 1) {
        product.qty = 1
      } else {
        product.qty = event.target.value
      }
    } else {
      product.qty = 1
    }
    newList[index].qty = product.qty
    setOrder(newList)
  }
  const buttonClass = (action: string) => {
    if (buttonClassName) {
      return value === min && action === 'minus'
        ? ' cursor-not-allowed transform text-txtGray stroke-current  ' +
            buttonClassName
        : 'transform cursor-pointer  active:scale-95 ' + buttonClassName
    } else {
      if (value === min && action === 'minus') {
        return 'transform cursor-not-allowed text-txtGray w-8 h-8 stroke-current'
      } else {
        return 'cursor-pointer transform w-8 h-8 active:scale-95'
      }
    }
  }
  return (
    <div className="flex justify-center items-center">
      <Minus
        className={buttonClass('minus')}
        onClick={() => value > min && handleQuantity('decrease')}
      />
      <input
        className={
          className
            ? className
            : 'text-txtBlack font-bold text-center bg-transparent text-titleSize w-12 focus:outline-none'
        }
        type="number"
        min={1}
        step={1}
        max={max}
        value={value ? value : 1}
        onInput={handleChange}
      />
      <Plus
        className={buttonClass('plus')}
        onClick={() => handleQuantity('increase')}
      />
    </div>
  )
}

export default CustomNumberInput
