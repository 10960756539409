import React, { ChangeEvent, SetStateAction, useEffect, useState } from 'react'
import Input from '../../input/Input'
import { checkoutStyle } from '../../../theme/theme'
import CustomCheckbox from '../../customFormElements/customCheckbox/CustomCheckbox'
import Button from '../../button/Button'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import CustomSelect from '../../customFormElements/customSelect/CustomSelect'
import CountryList from '../../../assets/json/states.json'

interface IFormValues {
  company: string
  deliveryDate: Date | undefined
  pickupDate: Date | undefined
  isDelivery: boolean
  isTaxable: boolean
  isAssemble: boolean
  isTouchUpKit: boolean
}
interface IAddress {
  address: string
  city: string
  state: string
  zip: string
}
const defaultSelected = {
  state: '',
  code: '',
  cities: []
}
interface ICheckoutForm {
  confirmOrder(formValues: {}): void
  isLoading: boolean
  formFields: IFormValues
  setFormFields: SetStateAction<any>
  setDeliveryAddress: SetStateAction<any>
  deliveryAddress: IAddress
}

const CheckoutForm = (props: ICheckoutForm) => {
  const {
    confirmOrder,
    isLoading,
    formFields,
    setFormFields,
    deliveryAddress,
    setDeliveryAddress
  } = props
  useEffect(() => {
    if (deliveryAddress.state && deliveryAddress.city)
      CountryList.forEach(item => {
        return item.state === deliveryAddress.state && setSelectedState(item)
      })
  }, [])

  const [errorMsg, setErrorMsg] = useState('')
  const [selectedState, setSelectedState] =
    useState<{ state: string; code: string; cities: string[] }>(defaultSelected)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name: string = event.target.name
    const value: string = event.target.value
    setFormFields({ ...formFields, [name]: value })
  }
  const handleAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name: string = event.target.name
    const value = event.target.value
    setDeliveryAddress({ ...deliveryAddress, [name]: value })
  }
  const handleSelectChange = (
    event: ChangeEvent<HTMLSelectElement>,
    name: string
  ) => {
    const value = event.target.value
    CountryList.forEach(item => {
      return name !== 'city' && item.state === value && setSelectedState(item)
    })
    if (name === 'state') {
      setDeliveryAddress({ ...deliveryAddress, [name]: value, city: '' })
      setFormFields({ ...formFields, isTaxable: false })
    } else setDeliveryAddress({ ...deliveryAddress, [name]: value })
  }
  const handleCheckboxChange = (name: keyof IFormValues) => {
    if (name === 'isDelivery') {
      if (formFields.isDelivery) {
        setFormFields({
          ...formFields,
          [name]: !formFields[name],
          deliveryDate: undefined
        })
      } else {
        setFormFields({
          ...formFields,
          [name]: !formFields[name],
          pickupDate: undefined
        })
      }
    } else {
      setFormFields({ ...formFields, [name]: !formFields[name] })
    }
  }
  function onDeliveryDateChange(date: any) {
    setFormFields({ ...formFields, deliveryDate: date, pickupDate: undefined })
    setErrorMsg('')
  }
  function onPickupDateChange(date: any) {
    setFormFields({ ...formFields, pickupDate: date, deliveryDate: undefined })
    setErrorMsg('')
  }
  const showSelectedDateType = () => {
    if (formFields.deliveryDate) {
      return 'Delivery'
    } else if (formFields.pickupDate) {
      return 'Pickup'
    }
  }
  return (
    <div className="desktop:w-2/5 mobile560px:min-w-26rem ">
      <h2 className="text-left mb-12 ">Order Details</h2>
      <form
        autoComplete="off"
        onSubmit={e => {
          if (formFields.pickupDate || formFields.deliveryDate) {
            e.preventDefault()
            confirmOrder(formFields)
          } else {
            e.preventDefault()
            setErrorMsg('Select date!')
          }
        }}>
        <div className="flex flex-col w-auto max-w-lg desktop:w-auto bg-white shadow-containerShadow rounded-2xl px-7 py-14">
          <Input
            classOverride
            className={checkoutStyle['textInput']}
            name="company"
            type="text"
            value={formFields.company}
            placeholder="Company Name"
            required={true}
            onChange={e => handleChange(e)}
          />
          <Input
            classOverride
            className={checkoutStyle['textInput']}
            name="address"
            type="text"
            value={deliveryAddress.address}
            placeholder="Address"
            required={true}
            onChange={e => handleAddressChange(e)}
          />
          <CustomSelect
            stateList={CountryList}
            value={deliveryAddress.state}
            name="State"
            handleChange={e => handleSelectChange(e, 'state')}
          />
          <div className=" flex  xl:flex-row gap-4">
            <CustomSelect
              citiesList={selectedState.cities}
              value={deliveryAddress.city}
              name="City"
              handleChange={e => handleSelectChange(e, 'city')}
            />
            <div className="w-2/3">
              <Input
                classOverride
                className={checkoutStyle['textInput'] + ' w-1/3'}
                name="zip"
                type="text"
                value={deliveryAddress.zip}
                placeholder="Zip"
                required={true}
                onChange={e => handleAddressChange(e)}
              />
            </div>
          </div>
          <div
            className={` w-full border border-transparent mt-5 text-sm text-txtBlack tablet:text-base font-normal focus:outline-none focus:border rounded-2xl h-14 bg-inputBg2 flex items-center relative ${
              errorMsg && '  border-red-600'
            }`}>
            <span className="absolute z-10 pointer-events-none text-txtGray right-4">
              {showSelectedDateType()}
            </span>
            <DayPickerInput
              format="MM-DD-YY"
              style={{ width: '100%' }}
              inputProps={{
                required: true,
                readOnly: true,
                className:
                  'w-full min-w-full px-4 text-sm text-txtBlack tablet:text-base font-normal focus:outline-none focus:border rounded-2xl h-14 bg-transparent'
              }}
              placeholder={formFields.isDelivery ? 'Delivery date' : 'Pick up'}
              onDayChange={
                formFields.isDelivery
                  ? onDeliveryDateChange
                  : onPickupDateChange
              }
              value={
                formFields.isDelivery
                  ? formFields.deliveryDate
                  : formFields.pickupDate
              }
              dayPickerProps={{
                disabledDays: {
                  daysOfWeek: [0, 6],
                  before: moment().add(2, 'weeks').toDate()
                }
              }}
            />
          </div>
          {errorMsg && (
            <span className=" text-xs font-medium tablet:text-sm w-full pl-4 text-red-600">
              {errorMsg}
            </span>
          )}
          <div className="grid mt-5 grid-rows-2 grid-flow-col px-4 gap-5">
            <div className={checkoutStyle['checkboxDiv']}>
              <CustomCheckbox
                name="isDelivery"
                checked={formFields.isDelivery}
                onClick={(name: keyof IFormValues) =>
                  handleCheckboxChange(name)
                }
              />
              <h3 className={checkoutStyle['checkboxTitles']}>Delivery</h3>
            </div>
            {deliveryAddress.state === 'Illinois' && (
              <div className={checkoutStyle['checkboxDiv']}>
                <CustomCheckbox
                  name="isTaxable"
                  checked={formFields.isTaxable}
                  onClick={(name: keyof IFormValues) =>
                    handleCheckboxChange(name)
                  }
                />
                <h3 className={checkoutStyle['checkboxTitles']}>Taxable</h3>
              </div>
            )}
            <div className={checkoutStyle['checkboxDiv']}>
              <CustomCheckbox
                name="isAssemble"
                checked={formFields.isAssemble}
                onClick={(name: keyof IFormValues) =>
                  handleCheckboxChange(name)
                }
              />
              <h3 className={checkoutStyle['checkboxTitles']}>Assemble</h3>
            </div>
            <div className={checkoutStyle['checkboxDiv']}>
              <CustomCheckbox
                name="isTouchUpKit"
                checked={formFields.isTouchUpKit}
                onClick={(name: keyof IFormValues) =>
                  handleCheckboxChange(name)
                }
              />
              <h3 className={checkoutStyle['checkboxTitles']}>touch up kit</h3>
            </div>
          </div>
          <div className="mt-10">
            <p className="text-sm font-medium text-txtBlack">
              <span className="text-danger">*</span>the delivery and pickup date
              is estimated and will be communicated after order confirmation
            </p>
            <p className="text-sm font-medium text-txtBlack">
              <span className="text-danger">*</span>
              the shipping charge will be calculated after the order
              confirmation
            </p>
          </div>
          <div className="flex justify-center mt-16">
            <Button isLoading={isLoading} type="submit" title="Confirm" />
          </div>
        </div>
      </form>
    </div>
  )
}

export default CheckoutForm
