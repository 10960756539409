import React, { useContext, useEffect, useState } from 'react'
import { OrderContext } from '../../services/context/orderContext/OrderContext'
import CustomNumberInput from '../customFormElements/customNumberInput/CustomNumberInput'
import { menuStyles, orderStyle } from '../../theme/theme'
import { ReactComponent as Trash } from '../../assets/images/svg/trash.svg'
import Button from '../button/Button'
import { useHistory } from 'react-router-dom'
import CloseButton from '../closeButton/CloseButton'

interface IProduct {
  sku: string
  name: string
  description: string
  qty: number
  price: number
  hinge: string
  drawer?: 'Yes' | 'No'
  isCustom?: boolean
}
interface IOrderDrawer {
  toggle: () => void
  showOrder: boolean
}
const OrderDrawer = (props: IOrderDrawer) => {
  const { toggle, showOrder } = props
  useEffect(() => {
    if (!showOrder) {
      document.body.setAttribute('style', 'overflow:hidden')
    }
    showOrder && document.body.setAttribute('style', 'overflow:unset')
  }, [showOrder])

  const order = useContext(OrderContext)
  const [selected, setSelected] = useState<number>(-1)
  const [subtotal, setSubtotal] = useState<number>(0)
  const orderLength = order.currentOrder.length
  const setSelectedItem = (sku: string, index: number) => {
    return selected === index ? setSelected(-1) : setSelected(index)
  }
  useEffect(() => {
    const Subtotal = () => {
      let price: number = 0
      order.currentOrder.map((product: IProduct) => {
        return (price = price + product.qty * product.price)
      })
      return setSubtotal(price)
    }
    Subtotal()
  }, [order.currentOrder])

  const checkSelected = (index: number) => {
    if (selected === index) {
      return orderStyle['productSelected']
    } else return orderStyle['product']
  }
  const trashClass = (index: number) => {
    return index === selected
      ? 'absolute right-0 text-danger transition cursor-pointer transform duration-500 -translate-x-2 tablet:translate-x-2'
      : 'absolute right-0 text-danger transition cursor-pointer transform duration-500 -translate-x-4 tablet:-translate-x-2'
  }
  const history = useHistory()
  const handleCheckout = () => {
    history.push('/checkout')
    toggle()
  }
  return (
    <>
      <div
        className={!showOrder ? 'overlay' : 'overlay-hide'}
        onClick={toggle}
      />

      <div
        className={
          showOrder ? menuStyles['orderShow'] : menuStyles['orderHide']
        }
        onClick={e => {
          e.stopPropagation()
        }}>
        <CloseButton
          className=" top:16 right-8"
          currentColor="black"
          onClick={toggle}
        />
        {orderLength ? (
          <div className="flex flex-col  h-full">
            <h2 className=" px-5 tablet:px-14 pb-5">Your Order</h2>
            <div className="px-5 tablet:px-14 overflow-y-auto z-0 box-border  flex-auto">
              {order.currentOrder.map((product: IProduct, index: number) => {
                return (
                  <div
                    key={product.sku + index}
                    className="flex relative justify-center items-center">
                    <div
                      onClick={() => setSelectedItem(product.sku, index)}
                      className={checkSelected(index)}>
                      <div className="flex flex-col w-full ">
                        {(product.hinge ||
                          product.drawer ||
                          product.isCustom) && (
                          <div className={' flex gap-2 absolute top-4 right-4'}>
                            {product.isCustom && (
                              <span
                                title="Product is custom size."
                                className=" px-2 bg-white w-auto text-txtBlack text-sm text-center font-bold h-7 rounded-full border flex items-center justify-center">
                                Custom
                              </span>
                            )}
                            {product.drawer && (
                              <span
                                title="Product has drawer."
                                className="px-2 bg-white w-auto text-txtBlack text-sm text-center font-bold h-7 rounded-full border flex items-center justify-center">
                                Drawer
                              </span>
                            )}
                            {product.hinge && (
                              <span
                                title={`${
                                  product.hinge === 'L'
                                    ? 'Left hinging'
                                    : 'Right hinging'
                                }`}
                                className=" w-7 text-txtBlack text-center font-bold h-7 rounded-full border flex items-center justify-center">
                                {product.hinge}
                              </span>
                            )}
                          </div>
                        )}
                        <h3 className={'text-left ' + orderStyle['titlesGray']}>
                          {product.sku}
                        </h3>
                        <div className="flex w-full flex-wrap mt-3 tablet:mt-2 tablet:flex-nowrap">
                          <div className="flex flex-auto w-full tablet:w-1/2 flex-col ">
                            <h3 className={orderStyle['titleBlack']}>
                              {product.name}
                            </h3>
                            <div className={orderStyle['description']}>
                              {product.description}
                            </div>
                          </div>
                          <div className="mt-5 w-full justify-between flex-row-reverse tablet:flex-row tablet:mt-0 flex tablet:w-1/2 ">
                            <div
                              className="z-20 tablet:w-1/2 tablet:flex-auto"
                              onClick={e => e.stopPropagation()}>
                              <h3
                                className={
                                  'mb-2 hidden tablet:block ' +
                                  orderStyle['titlesGray']
                                }>
                                Quantity
                              </h3>
                              <CustomNumberInput
                                className="text-txtBlack scale font-bold  text-center text-lg w-12 focus:outline-none"
                                buttonClassName="w-6"
                                value={product.qty}
                                index={index}
                                min={1}
                                max={100}
                                products={order.currentOrder}
                                setOrder={order.updateOrder}
                              />
                            </div>
                            <div className="tablet:flex-auto items-center tablet:w-1/2">
                              <h3
                                className={
                                  'hidden tablet:block ' +
                                  orderStyle['titlesGray']
                                }>
                                Price
                              </h3>
                              <div className={orderStyle['values']}>
                                {'$' + (product.price * product.qty).toFixed(2)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Trash
                      onClick={() => order.removeFromOrder(index)}
                      className={trashClass(index)}>
                      X
                    </Trash>
                  </div>
                )
              })}
            </div>
            <div>
              <div className="flex px-5 tablet:px-14 w-full pt-5 justify-between">
                <h2 className={orderStyle['titleBlack']}>Subtotal</h2>
                <div className="text-txtGray font-bold text-xl">
                  {'$ ' + subtotal.toFixed(2)}
                </div>
              </div>
              <div className="w-full mt-10 flex justify-center">
                <Button title="Checkout" onClick={handleCheckout} />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex h-full justify-center items-center">
            <h2>Your order will appear hear</h2>
          </div>
        )}
      </div>
    </>
  )
}

export default OrderDrawer
