import React, { ChangeEvent } from 'react'
import { ReactComponent as Triangle } from '../../../assets/images/svg/triangle.svg'

interface ICustomSelect {
  stateList?: { state: string; code: string; cities: string[] }[]
  citiesList?: string[]
  value: string | undefined
  name: 'State' | 'City'
  handleChange: (e: ChangeEvent<HTMLSelectElement>) => void
}

const CustomSelect = (props: ICustomSelect) => {
  const { value, name, handleChange, stateList, citiesList } = props
  const renderOptions = () => {
    if (!value) {
      return <option value="">{name}</option>
    }
  }
  return (
    <div className="flex w-full mt-5 h-14 items-center relative ">
      <select
        className={`w-full border border-transparent px-4 text-sm ${
          !value ? 'text-txtGray' : 'text-txtBlack'
        } tablet:text-base font-normal focus:outline-none 
        focus:border rounded-2xl h-14 bg-inputBg2 appearance-none cursor-pointer focus:outline-none z-1 `}
        value={value}
        required={true}
        onChange={handleChange}>
        {renderOptions()}
        {name === 'State'
          ? stateList?.map((item, i) => {
              return (
                <option key={i} value={item.state}>
                  {item.state}
                </option>
              )
            })
          : citiesList?.map((item, i) => {
              return (
                <option key={i} value={item}>
                  {item}
                </option>
              )
            })}
      </select>
      <Triangle className="absolute pointer-events-none right-2" />
    </div>
  )
}

export default CustomSelect
