import React, { MouseEventHandler } from 'react'

interface IButton extends React.HTMLProps<HTMLButtonElement> {
  className?: string
  title?: string
  type?: 'button' | 'submit' | 'reset' | undefined
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
  secondaryType?: boolean
  styleOverride?: boolean
  disabled?: boolean
  isLoading?: boolean
  content?: any
}

const Button = (props: IButton) => {
  const {
    className,
    title,
    type,
    onClick,
    secondaryType,
    styleOverride,
    isLoading,
    content,
    disabled
  } = props
  const defaultClass = `transform active:scale-95 flex justify-center items-center focus:outline-none text-sm min-h-button-h tablet:text-base active:text-xl font-bold h-14 w-60  ${
    disabled ? ' bg-txtGray ' : ' bg-gradient-to-r shadow-btnShadow'
  }  from-btnStart to-btnEnd text-white  rounded-2xl `
  const secondaryClass =
    'transform active:scale-95 cursor-pointer bg-transparent text-sm min-h-button-h tablet:text-base border border-txtGray text-txtGray focus:outline-none active:scale-95 font-bold w-60  h-14  rounded-2xl '

  const cursorClass = isLoading
    ? 'cursor-not-allowed'
    : disabled
    ? 'cursor-not-allowed'
    : 'cursor-pointer'
  const buttonClass = secondaryType
    ? secondaryClass
    : defaultClass + cursorClass
  return (
    <button
      onClick={onClick}
      disabled={isLoading || disabled}
      className={styleOverride ? className : buttonClass + ' ' + className}
      type={type ? type : 'button'}>
      {!isLoading ? (
        content ? (
          content
        ) : (
          title
        )
      ) : (
        <div className=" animate-spin  ease-linear rounded-full border-4  h-8 w-8  border-top-color" />
      )}
    </button>
  )
}

export default Button
