import React, { CSSProperties } from 'react'

const myStyles: CSSProperties = {
  top: ' 50%'
}
const LoadingScreen = () => (
  <div className="w-full flex justify-center content-center h-full fixed block top-0 left-0 bg-gray-600 opacity-75 z-50">
    <span
      className="  text-green-500 opacity-100  block relative"
      style={myStyles}>
      loading
    </span>
  </div>
)

export default LoadingScreen
