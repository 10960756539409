import React from 'react'
import Slider, { CustomArrowProps } from 'react-slick'
import { ReactComponent as Left } from '../../assets/images/svg/left.svg'
import { ReactComponent as Right } from '../../assets/images/svg/right.svg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

interface IProps {
  slides: Array<string>
}

const NextArrow = ({
  currentSlide,
  slideCount,
  ...props
}: CustomArrowProps) => (
  <div {...props}>
    <Right className="transform active:scale-90" />
  </div>
)
const PrevArrow = ({
  currentSlide,
  slideCount,
  ...props
}: CustomArrowProps) => (
  <div {...props}>
    <Left className="transform active:scale-90" />
  </div>
)
const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
}
const SlickCarousel = (props: IProps) => {
  const { slides } = props

  return (
    // <Slider className="w-full  h-auto flex justify-center" {...settings}>
    // removed slick carousel on images, for netlify error. can be investigate later
    <div className="w-full  h-auto flex justify-center">
      {slides.length ? (
        slides.map((slide: string, i: number) => {
          return (
            <div key={i} className="flex justify-center items-center">
              <img
                className="max-h-80 w-full tablet:max-h-full h-full mx-auto max-w-16 tablet:max-w-full"
                src={slide}
                alt="product_img"
              />
            </div>
          )
        })
      ) : (
        <div className=" h-full w-full  text-xl font-bold text-center">
          No picture available for this product!
        </div>
      )}
    </div>
  )
}

export default SlickCarousel
