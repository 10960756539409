import React from 'react'
import moment from 'moment'
import { orderHistory } from '../../../theme/theme'
import Button from '../../button/Button'

interface INameToValueMap {
  [key: string]: any
}
interface IAdminListItem {
  item: {
    createdAt: any
    id: string
    paymentType: string
    invoiceId: string
    company: string
    totalPrice: number
    createdBy: string
    isPayed: boolean
    deliveryDate: string | Date | undefined
    pickupDate: string | Date | undefined
  }
  index: number
  currentIndex: number
  setCurrentIndex: (i: number) => void
  isCheckVisible: boolean
  setIsCheckVisible: (i: boolean) => void
  toggleModal: () => void
  updateOrder: () => any
  isLoading: INameToValueMap | undefined
}
const AdminListItem = (props: IAdminListItem) => {
  const {
    item,
    currentIndex,
    index,
    setCurrentIndex,
    toggleModal,
    setIsCheckVisible,
    isCheckVisible,
    updateOrder,
    isLoading
  } = props

  const toggleMarkAsPayed = () => {
    setIsCheckVisible(!isCheckVisible)
  }

  return (
    <div
      className={orderHistory['listItemContainer']}
      key={item.id}
      onClick={() => {
        setCurrentIndex(index)
        toggleModal()
      }}>
      {isLoading?.[item.id] && (
        <div
          className={
            'absolute flex justify-center items-center top-0 right-0 bottom-0 bg-overlay left-0 '
          }>
          <div className=" animate-spin  ease-linear rounded-full border-4  h-8 w-8  border-top-color" />
        </div>
      )}
      <h2
        className={
          orderHistory['listItemNr'] + ' tablet:w-30 mb-5 tablet:mb-0 '
        }>
        {item.invoiceId}
      </h2>
      <h2 className={orderHistory['listItem'] + ' tablet:w-40'}>
        {moment(item.createdAt.toDate()).format('MM-DD-YY')}
      </h2>
      <h2 className={orderHistory['listItem'] + ' tablet:w-52 break-all'}>
        {item.createdBy}
      </h2>
      <h2 className={orderHistory['listItem'] + ' tablet:w-60'}>
        {item.company}
      </h2>
      <div
        onClick={e => e.stopPropagation()}
        className={
          orderHistory['listItem'] +
          ' tablet:w-36 flex justify-center text-center items-center right-5 top-5 absolute tablet:static'
        }>
        <div
          onClick={() => {
            !item.isPayed && toggleMarkAsPayed()
            setCurrentIndex(index)
          }}
          className={
            item.isPayed
              ? orderHistory['itemPaid']
              : orderHistory['itemNotPaid']
          }>
          {!item.isPayed && isCheckVisible && index === currentIndex && (
            <div
              onClick={e => {
                e.stopPropagation()
                updateOrder()
                toggleMarkAsPayed()
              }}
              className="absolute bg-white -top-11 tablet:-top-5 desktop:-top-4 xl:-top-1 flex items-center justify-center w-20 xl:w-32
           rounded-md px-2 h-10 font-bold xl:text-lg text-sm text-success border-2 border-success">
              Check
            </div>
          )}
          {item.isPayed
            ? item.paymentType === 'credit'
              ? 'Credit card'
              : 'Check'
            : 'Unpaid'}
        </div>
      </div>
      <h2 className={orderHistory['listItem'] + ' tablet:w-36'}>
        $ {item.totalPrice.toFixed(2)}
      </h2>
      <div className="flex pointer-events-auto mt-5 items-center justify-center tablet:hidden w-full ">
        <Button
          styleOverride={true}
          className={
            'max-w-10 transform border-2 border-primary text-primary cursor-pointer bg-transparent' +
            ' text-sm min-h-button-h tablet:text-base ' +
            'focus:outline-none active:scale-95 font-bold w-60  h-14  rounded-2xl '
          }
          onClick={() => {
            setCurrentIndex(index)
            toggleModal()
          }}
          title="View details"
        />
      </div>
    </div>
  )
}

export default AdminListItem
