import React from 'react'
import { loginStyles } from '../../theme/theme'
import MobileFooter from '../../components/mobileFooter/MobileFooter'
import SignupForm from '../../components/forms/signupForm/SignupForm'

const Signup = () => {
  return (
    <div className={loginStyles['background']}>
      <div className={loginStyles['cardContainer']}>
        <div className={loginStyles['formContainer']}>
          <SignupForm />
        </div>
      </div>
      <MobileFooter />
    </div>
  )
}

export default Signup
