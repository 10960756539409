import React, { useContext, useEffect, useState } from 'react'
import CheckoutForm from '../../components/forms/checkoutForm/CheckouForm'
import { OrderContext } from '../../services/context/orderContext/OrderContext'
import { AuthContext } from '../../services/context/authContext/AuthContext'
import { db } from '../../services/firebaseConfig'
import firebase from 'firebase/app'
import PaymentMethod from '../../components/checkoutComponents/paymentMethod/PaymentMethod'
import moment from 'moment'
import { customAlphabet } from 'nanoid/non-secure'
import { pdf } from '@react-pdf/renderer'
import PdfContent from '../../components/pdf/PdfContent'
import XLSX from 'xlsx'
import CheckoutOrderList from '../../components/checkoutComponents/checkoutOrderList/CheckoutOrderList'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { stripePublishableKy } from '../../services/stripeConfig'

// @ts-ignore
const promise = loadStripe(stripePublishableKy)
interface IProduct {
  qty: number
  sku: string
  description: string
  hinge: string
  weight: string
  cubes: string
  price: number
  type: string
  isCustom: boolean
  drawer: 'Yes' | 'No'
}
interface IDashboardVariables {
  cabinetAssembleFee: number
  customCabinetAssembleFee: number
  discount: number
  email: string
  pantryAssembleFee: number
  touchUpKit: number
}
interface IAddress {
  address: string
  city: string
  state: string
  zip: string
}
const addressValues = {
  address: '',
  city: '',
  state: '',
  zip: ''
}
interface IFormValues {
  id: string
  invoiceId: string
  company: string
  createdAt: any
  createdBy: string
  deliveryDate: Date | undefined | string
  isDelivery: boolean
  isPickup: boolean | null
  isAssemble: boolean
  pickupDate: Date | undefined | string
  isTaxable: boolean
  isTouchUpKit: boolean
  products: any[]
  subtotal: number
  totalPrice: number
  assembleFee: number
  touchUpKit: number
  billingAddress: IAddress
}
const formFieldsValues = {
  id: '',
  company: '',
  createdAt: '',
  createdBy: '',
  isPickup: null,
  deliveryDate: undefined,
  pickupDate: undefined,
  isDelivery: false,
  isTaxable: false,
  isAssemble: false,
  isTouchUpKit: false
}

const dashboardValues = {
  cabinetAssembleFee: 0,
  customCabinetAssembleFee: 0,
  discount: 0,
  email: '',
  pantryAssembleFee: 0,
  touchUpKit: 0
}
const Checkout = () => {
  const history = useHistory()
  const order = useContext(OrderContext)
  const loggedInUser = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormValues] = useState(formFieldsValues)
  const [billingAddress, setBillingAddress] = useState<IAddress>(addressValues)
  const [deliveryAddress, setDeliveryAddress] =
    useState<IAddress>(addressValues)
  const [isPayment, setIsPayment] = useState(false)
  const [dashboardVariables, setDashboardVariables] =
    useState<IDashboardVariables>(dashboardValues)
  const [subtotal, setSubtotal] = useState<number>(0)
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [assemblePrice, setAssemblePrice] = useState<number>(0)
  const [xlsxProductsArray, setXlsxProductsArray] = useState([] as any)
  const [orderId, setOrderId] = useState<string>('')
  const [invoiceId, setInvoiceId] = useState<string>('')
  const [deliveryIsBilling, setDeliveryIsBilling] = useState<boolean>(false)
  const ChicagoSalesTax: number = 10.25
  // ----------------------- effect for calculate total products price -----------------------
  useEffect(() => {
    if (!order.currentOrder.length) {
      return history.push('/')
    }
    let price: number = 0
    order.currentOrder.map((product: { qty: number; price: number }) => {
      return (price = price + product.qty * product.price)
    })
    return setSubtotal(price)
  }, [subtotal, order, history])

  // ----------------------- effect for calculate total order price -----------------------
  useEffect(() => {
    if (!order.currentOrder.length) {
      return
    }
    const total =
      subtotal +
      (formValues.isAssemble ? assemblePrice : 0) +
      (formValues.isTouchUpKit ? dashboardVariables?.touchUpKit : 0)
    const calculatedPrice = formValues.isTaxable
      ? total + total * (ChicagoSalesTax / 100)
      : total
    return setTotalPrice(calculatedPrice)
  }, [
    order.currentOrder.length,
    subtotal,
    formValues.isTouchUpKit,
    formValues.isAssemble,
    formValues.isTaxable,
    assemblePrice,
    dashboardVariables?.touchUpKit
  ])
  useEffect(() => {
    if (!order.currentOrder.length) {
      return
    }
    const isAdmin = loggedInUser?.role.toLowerCase() === 'admin'
    let productList = [] as any
    let adminDiscount: number = 1
    const getAdminDiscount = async () => {
      const response = db.collection('dashboard').doc('dashboardVariables')
      const doc = await response.get()
      if (doc.exists) {
        const data = doc.data()
        setDashboardVariables(data as IDashboardVariables)
        adminDiscount = data?.discount
      }
    }
    getAdminDiscount()
      .then(() => {
        const userDiscount = loggedInUser?.discount
        return order.currentOrder.map((product: IProduct) => {
          const {
            qty,
            sku,
            description,
            hinge,
            weight,
            cubes,
            price,
            type,
            isCustom,
            drawer
          } = product
          //get original item price before discount is applied
          const fullPrice = (Number(price) * 100) / (100 - Number(userDiscount))
          //apply admin discount on original price for excel list if user is admin Admin discounted price equals price
          const adminDiscountPrice = isAdmin
            ? price
            : Number(fullPrice) -
              (Number(fullPrice) * Number(adminDiscount)) / 100
          const customSize = isCustom ? 'Yes' : '-'
          const hasDrawer = drawer === 'Yes' ? 'Yes' : '-'
          const productData = [
            qty,
            sku,
            description,
            hinge,
            weight,
            cubes,
            adminDiscountPrice,
            type.split(' ')[0],
            customSize,
            hasDrawer
          ]
          return productList.push(productData)
        })
      })
      .then(() => setXlsxProductsArray(productList))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.currentOrder])

  const handleEncodedPdfXlsx = async (orderID: string, invoiceId: string) => {
    // ---------------------------encode data to base64  PDF-------------------
    const instance = pdf()
    const combineValues = {
      invoiceId: invoiceId,
      id: orderID,
      products: order.currentOrder,
      subtotal: subtotal,
      totalPrice: totalPrice,
      assembleFee: assemblePrice,
      touchUpKit: Number(dashboardVariables.touchUpKit),
      billingAddress: deliveryIsBilling ? deliveryAddress : billingAddress
    }
    const pdfObject: IFormValues = { ...formValues, ...combineValues }

    pdfObject.billingAddress = deliveryIsBilling
      ? deliveryAddress
      : billingAddress

    instance.updateContainer(<PdfContent order={pdfObject} />)
    const blob = await instance.toBlob()
    const reader = new window.FileReader()
    reader.readAsDataURL(blob)
    const pdfEncode = await new Promise(resolve => {
      reader.onloadend = () => resolve(reader.result)
    })

    // xlsx encode
    const { state, address, zip, city } = deliveryAddress
    let XlData = [
      ['Address'],
      [address + ', ' + city + ', ' + state + ', ' + zip],
      [
        'Quantity',
        'SKU',
        'Description',
        'Hinging',
        'Weight',
        'Cubes',
        'Price',
        'Style',
        'Custom size',
        'Drawer'
      ],
      ...xlsxProductsArray
    ]
    const ws = XLSX.utils.aoa_to_sheet(XlData)
    const wb = XLSX.utils.book_new()
    ws['!cols'] = [
      { wch: 10 },
      { wch: 12 },
      { wch: 60 },
      { wch: 10 },
      { wch: 8 },
      { wch: 7 },
      { wch: 14 }
    ]
    ws['!merges'] = [
      // Set the cell merge of A1-G1
      //  r = row c = column
      { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 6 } }
    ]
    // style available only in pro version
    ws['A1'].s = {
      font: {
        sz: 13,
        bold: true,
        color: {
          rgb: 'FFFFAA00'
        }
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrap_text: true
      }
    }
    //height of rows
    ws['!rows'] = [{ hpx: 20 }]

    XLSX.utils.book_append_sheet(wb, ws, 'products')
    /* generate base64 string */
    const encodedData = await XLSX.write(wb, { type: 'base64' })

    return {
      xlsx: encodedData,
      pdf: pdfEncode
    }
  }

  const handleConfirmForm = async () => {
    if (order.currentOrder.length > 0) {
      setIsPayment(true)
    } else alert('Please add products to your order.')
  }
  const handleConfirmOrder = async (values: { payLater: boolean }) => {
    const { payLater } = values
    if (
      !deliveryIsBilling &&
      !billingAddress?.address &&
      !billingAddress.state &&
      !billingAddress.zip &&
      !billingAddress.city
    ) {
      alert('complete all billing form fields!')
    } else {
      if (payLater) {
        return postOrder('complete', 'cash')
      } else {
        return await postOrder('incomplete', 'credit')
      }
    }
  }
  const postOrder = async (status: string, paymentType: string) => {
    setIsLoading(true)

    const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvxz', 7)
    const invoiceId = nanoid()
    setInvoiceId(invoiceId)
    if (formValues) {
      setIsLoading(true)
      const doc_ref = await db.collection('orders').doc()
      return await handleEncodedPdfXlsx(doc_ref.id, invoiceId).then(
        async data => {
          const delivery = formValues.deliveryDate
            ? moment(formValues.deliveryDate).format('MMMM DD YYYY')
            : moment(formValues.pickupDate).format('MMMM DD YYYY')
          return await doc_ref
            .set({
              status: status,
              createdBy: loggedInUser?.email,
              id: doc_ref.id,
              invoiceId: invoiceId,
              createdAt: firebase.firestore.Timestamp.now(),
              company: formValues.company,
              billingAddress: deliveryIsBilling
                ? deliveryAddress
                : billingAddress,
              deliveryAddress: deliveryAddress,
              deliveryDate: formValues.deliveryDate ? delivery : null,
              pickupDate: formValues.pickupDate ? delivery : null,
              totalPrice: totalPrice,
              subtotal: subtotal,
              isTaxable: formValues.isTaxable,
              isAssemble: formValues.isAssemble,
              isTouchUpKit: formValues.isTouchUpKit,
              touchUpKit: formValues.isTouchUpKit
                ? Number(dashboardVariables.touchUpKit)
                : 0,
              assembleFee: formValues.isAssemble ? assemblePrice : 0,
              products: order.currentOrder,
              encodedPdf: data.pdf,
              encodedXlsx: data.xlsx,
              isPayed: false,
              paymentType: paymentType
            })
            .then(() => {
              if (status === 'complete') {
                order.updateOrder([])
                setIsLoading(false)
                history.push('/confirm', { orderId: doc_ref.id })
              } else {
                setOrderId(doc_ref.id)
                return { orderId: doc_ref.id, orderNr: invoiceId }
              }
            })
            .catch(err => {
              console.log(err)
              setIsLoading(false)
            })
        }
      )
    }
  }

  return (
    <div className=" px-5 py-5 tablet:px-10 tablet:py-10 xl:px-24">
      <h1 className="">Checkout</h1>
      {isPayment && (
        <h2 className={'mt-10 cursor-pointer'}>
          <span onClick={() => setIsPayment(false)} className={'text-txtGray'}>
            Checkout
          </span>
          /Payment
        </h2>
      )}
      <div className="flex flex-col desktop:flex-row w-full gap-5 xl:gap-10 mt-6 tablet:mt-12">
        {!isPayment ? (
          <CheckoutForm
            formFields={formValues}
            deliveryAddress={deliveryAddress}
            setDeliveryAddress={setDeliveryAddress}
            setFormFields={setFormValues}
            confirmOrder={handleConfirmForm}
            isLoading={isLoading}
          />
        ) : (
          <Elements stripe={promise}>
            <PaymentMethod
              orderId={orderId}
              orderNr={invoiceId}
              totalPrice={totalPrice}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              deliveryIsBilling={deliveryIsBilling}
              setDeliveryIsBilling={setDeliveryIsBilling}
              address={billingAddress}
              setAddress={setBillingAddress}
              onSubmit={async values => {
                return await handleConfirmOrder(values)
              }}
            />
          </Elements>
        )}
        <CheckoutOrderList
          isAssemble={formValues.isAssemble}
          isTouchUpKit={formValues.isTouchUpKit}
          isTaxable={formValues.isTaxable}
          assemblePrice={assemblePrice}
          dashboardVariables={dashboardVariables}
          setAssemblePrice={setAssemblePrice}
          subtotal={subtotal}
          salesTax={ChicagoSalesTax}
          totalPrice={totalPrice}
        />
      </div>
    </div>
  )
}

export default withRouter(Checkout)
