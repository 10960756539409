import React, { useEffect, useState } from 'react'
import { db } from '../../services/firebaseConfig'

interface ISearchCategories {
  category: string
  setCategories(category: string, subcategory?: string): void

  subcategory: string
}

const SearchCategories = (props: ISearchCategories) => {
  const { category, setCategories } = props
  const [categoryList, setCategoryList] = useState([] as any)
  const [categoryIndex, setCategoryIndex] = useState<number>(-1)
  const [subcategoryIndex, setSubcategoryIndex] = useState<number>(-1)

  const list = category
    ? categoryList[categoryIndex].subcategories
    : categoryList
  const sizesList =
    categoryIndex > -1 && subcategoryIndex > -1
      ? categoryList[categoryIndex].subcategories[subcategoryIndex]
      : []
  const handleIndexes = (i: number) => {
    if (category) {
      setSubcategoryIndex(i)
    } else setCategoryIndex(i)
  }
  useEffect(() => {
    const fetchCategories = async () => {
      const itemList: any = []
      const response = db.collection('categories')
      const data = await response.get()
      data.docs.forEach(item => {
        itemList.push(item.data())
      })
      setCategoryList(itemList)
    }
    fetchCategories().catch(err => console.log(err))
  }, [])

  return (
    <div className=" w-full  xl:w-45">
      <h2 className=" mt-7  tablet:mt-auto">
        {category ? 'Subcategory' : 'Category'}
      </h2>

      <div className=" mt-6 grid grid-cols-1 desktop:grid-cols-2 gap-10">
        {list.map((item: any, i: number) => {
          return (
            <div
              key={i}
              className=" px-4 cursor-pointer capitalize flex items-center  justify-center h-20 text-lg tablet:text-xl
              font-medium text-txtBlack bg-white shadow-containerShadow rounded-2xl
             bg-gradient-to-r  hover:from-btnStart  hover:to-btnEnd hover:text-white
             transition  duration-500 ease-in-out text-center
              "
              onClick={() => {
                setCategories(item.name.toLowerCase())
                handleIndexes(i)
              }}>
              {item.name.toLowerCase()}
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default SearchCategories
