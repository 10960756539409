import React, { useState } from 'react'
import { modalStyles } from '../../theme/theme'
import CloseButton from '../closeButton/CloseButton'
import EditableContent from './editableContent/EditableContent'
import DescriptionContent from './descriptionContent/DescriptionContent'
import OrderContent from './orderContent/OrderContent'

type content = 'edit' | 'details' | 'order' | ''
interface IModal {
  content: content
  title?: string
  toggle: () => void
  modalVisible: boolean
  products: any[]
  setProducts?(products: any[]): void | undefined
  handleClick?(product: {}, type: 'add' | 'edit'): any | undefined
  index: number
}

const Modal = (props: IModal) => {
  const {
    toggle,
    modalVisible,
    products,
    title,
    handleClick,
    index,
    setProducts,
    content
  } = props
  const [modalTitle, setModalTitle] = useState('')

  return (
    <div
      className={
        modalVisible ? modalStyles['overlayShow'] : modalStyles['overlayHide']
      }>
      <div
        className={`${
          content !== 'order' && 'min-h-modal-mobile tablet:min-h-modal-h '
        } mx-5 z-50 relative  my-auto w-11/12  tablet:mx-auto max-w-50rem desktop:w-50rem   flex flex-col bg-white rounded-32`}>
        <div className=" h-24 rounded-t-32 min-h-6rem desktop:min-h-9rem desktop:h-36 px-14 bg-primary flex flex-col items-start justify-center relative ">
          <h2 className="font-bold text-lg desktop:text-2xl text-white">
            {title ? title : modalTitle}
          </h2>
          <CloseButton
            className=" top:7 right-7"
            currentColor="white"
            onClick={toggle}
          />
        </div>
        {content === 'details' && (
          <DescriptionContent
            setTitle={setModalTitle}
            products={products}
            index={index}
          />
        )}
        {content === 'order' && (
          <OrderContent index={index} orders={products} />
        )}
        {content === 'edit' && setProducts && handleClick ? (
          <EditableContent
            setTitle={setModalTitle}
            products={products}
            index={index}
            toggle={toggle}
            setProducts={setProducts}
            handleClick={handleClick}
            title={title}
          />
        ) : null}
      </div>
    </div>
  )
}

export default Modal
