import React, {
  ChangeEvent,
  FormEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react'
import Button from '../../button/Button'
import { checkoutStyle } from '../../../theme/theme'
import CustomCheckbox from '../../customFormElements/customCheckbox/CustomCheckbox'
import Input from '../../input/Input'
import { AuthContext } from '../../../services/context/authContext/AuthContext'

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  PaymentIntentResult,
  PaymentMethodResult,
  StripeCardElementChangeEvent
} from '@stripe/stripe-js'
import { db, functions } from '../../../services/firebaseConfig'
import { useHistory } from 'react-router-dom'
import { OrderContext } from '../../../services/context/orderContext/OrderContext'
import CountryList from '../../../assets/json/states.json'
import CustomSelect from '../../customFormElements/customSelect/CustomSelect'

interface IAddress {
  address: string
  city: string
  state: string
  zip: string
}
interface IPaymentMethod {
  onSubmit(data: { deliveryIsBilling: boolean; payLater: boolean }): any
  address: IAddress | undefined
  setAddress: SetStateAction<any>
  isLoading: boolean
  setIsLoading: SetStateAction<any>
  deliveryIsBilling: boolean
  setDeliveryIsBilling: SetStateAction<any>
  totalPrice: number
  orderId: string | ''
  orderNr: string | ''
}
const defaultSelected = {
  state: '',
  code: '',
  cities: []
}
const cardStyle = {
  style: {
    base: {
      color: '#07070c',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiasing',
      fontSize: '16px',
      '::placeholder': {
        color: '#b8b8b8'
      }
    },
    invalid: {
      color: 'rgba(220, 38, 38, 1)',
      iconColor: 'rgba(220, 38, 38, 1)'
    }
  },
  hidePostalCode: true
}

const PaymentMethod = (props: IPaymentMethod) => {
  const history = useHistory()
  const order = useContext(OrderContext)
  const user = useContext(AuthContext)
  const {
    onSubmit,
    setAddress,
    address,
    isLoading,
    totalPrice,
    orderId,
    orderNr,
    setIsLoading,
    deliveryIsBilling,
    setDeliveryIsBilling
  } = props

  const [payLater, setPayLater] = useState<boolean>(false)
  const [customerId, setCustomerId] = useState<string>('')
  const [error, setError] = useState('')
  const [disabled, setDisabled] = useState<boolean>(!payLater)
  const [selectedState, setSelectedState] =
    useState<{ state: string; code: string; cities: string[] }>(defaultSelected)

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    const customerRef = db.collection('stripe_customers').doc(user?.user_id)
    customerRef.get().then(data => {
      const customer_id = data.data()?.customer_id
      setCustomerId(customer_id)
    })
  }, [user?.user_id])

  useEffect(() => {
    if (address?.state && address?.city)
      CountryList.forEach(item => {
        return item.state === address.state && setSelectedState(item)
      })
  }, [])
  const handleChange = async (event: StripeCardElementChangeEvent) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    if (event.complete) {
      setDisabled(false)
    } else if (event.error || event.empty) {
      setDisabled(true)
      setError(event.error ? event.error.message : '')
    }
    !event.error && setError('')
  }
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (payLater) {
      return onSubmit({ deliveryIsBilling, payLater })
    } else {
      if (orderId) {
        setIsLoading(true)
        return createPayment(orderId, orderNr)
      } else {
        return await onSubmit({ deliveryIsBilling, payLater }).then(
          (res: { orderId: string; orderNr: string }) => {
            if (res) {
              const { orderId, orderNr } = res
              return createPayment(orderId, orderNr)
            } else {
              setIsLoading(false)
              alert('order request error, please contact developers.')
            }
          }
        )
      }
    }
  }
  const createPayment = async (
    promiseOrderId: string,
    promiseOrderNr: string
  ) => {
    if (!promiseOrderId) {
      return alert('Order request failed,Please contact developers')
    }
    const card = elements?.getElement(CardElement)
    if (!stripe || !elements || !card) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    if (error) {
      elements.getElement('card')?.focus()
      setDisabled(true)
      setIsLoading(false)
      return
    } else {
      setDisabled(false)
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          email: user?.email
        }
      })
      return stripePaymentMethodHandler(result, promiseOrderId, promiseOrderNr)
    }
  }
  const stripePaymentMethodHandler = (
    result: PaymentMethodResult,
    promiseOrderId: string,
    promiseOrderNr: string
  ) => {
    if (result.error) {
      // Show error in payment form
      setError(
        result.error.message
          ? result.error.message
          : 'payment error' + result.error
      )
      setIsLoading(false)
    } else {
      const confirmPaymentRequest = functions.httpsCallable(
        'confirmPaymentRequest'
      )
      return confirmPaymentRequest({
        amount: totalPrice.toFixed(2),
        customerId: customerId,
        userId: user?.user_id,
        paymentMethodId: result.paymentMethod.id,
        orderId: promiseOrderId,
        orderNr: promiseOrderNr
      })
        .then(response => {
          // Handle server response (see Step 4)
          handleServerResponse(response, promiseOrderId, promiseOrderNr)
        })
        .catch(err => {
          setIsLoading(false)
          console.log(err)
        })
    }
  }
  const handleServerResponse = (
    response: any,
    promiseOrderId: string,
    promiseOrderNr: string
  ) => {
    if (response.data.error) {
      // Show error from server on payment form
      setError(
        response.data.message ? response.data.message : response.data.error
      )
      setIsLoading(false)
    } else if (response.data.requires_action === true) {
      // Use Stripe.js to handle required card action
      // @ts-ignore
      stripe
        .handleCardAction(response.data.payment_intent_client_secret)
        .then(res => {
          return handleStripeJsResult(res, promiseOrderId, promiseOrderNr)
        })
    } else {
      // Show success message
      order.updateOrder([])
      setIsLoading(false)
      history.push('/confirm', { orderId: promiseOrderId })
      setIsLoading(false)
    }
  }
  function handleStripeJsResult(
    result: PaymentIntentResult,
    promiseOrderId: string,
    promiseOrderNr: string
  ) {
    if (result.error) {
      // Show error in payment form
      setError(result.error.message ? result.error.message : 'payment error')
      setIsLoading(false)
    } else {
      // The card action has been handled
      // The PaymentIntent can be confirmed again on the server
      const confirmPaymentRequest = functions.httpsCallable(
        'confirmPaymentRequest'
      )
      return confirmPaymentRequest({
        amount: totalPrice.toFixed(2),
        customerId: customerId,
        userId: user?.user_id,
        orderId: promiseOrderId,
        paymentIntentId: result.paymentIntent.id,
        orderNr: promiseOrderNr
      })
        .then(response => {
          // Handle server response
          handleServerResponse(response, promiseOrderId, promiseOrderNr)
        })
        .catch(err => {
          setIsLoading(false)
          console.log(err)
        })
    }
  }
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const key: string = event.target.name
    const value = event.target.value
    setAddress({ ...address, [key]: value })
  }
  const handleSelectChange = (
    event: ChangeEvent<HTMLSelectElement>,
    key: string
  ) => {
    const value = event.target.value
    CountryList.forEach(item => {
      return key !== 'city' && item.state === value && setSelectedState(item)
    })
    key === 'state'
      ? setAddress({ ...address, [key]: value, city: '' })
      : setAddress({ ...address, [key]: value })
  }
  const handleButtonDisabled = () => {
    if (payLater && deliveryIsBilling) {
      return false
    } else if (
      payLater &&
      !deliveryIsBilling &&
      address &&
      address.address &&
      address.city &&
      address.state &&
      address.zip
    ) {
      return false
    } else {
      return disabled
    }
  }
  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      className=" w-full desktop:w-2/5 tablet:min-w-26rem flex flex-col ">
      <h2 className="text-left mb-12 pl-2 ">Payment method</h2>

      {!payLater && (
        <div className={checkoutStyle['containerWhite'] + 'flex-col'}>
          <h3 className={'pl-2 text-left'}>Credit Card</h3>
          <div className={'flex flex-col gap-2 pl-2 mt-4 '}>
            <span className={'text-red-600 font-bold'}>
              ----testing cards(will be removed)
            </span>
            <span>
              Success:{' '}
              <strong className={'select-all'}> 4242 4242 4242 4242 </strong>
            </span>
            <span>
              Authentication:{' '}
              <strong className={'select-all'}> 4000 0025 0000 3155 </strong>
            </span>
            <span>
              Failed:{' '}
              <strong className={'select-all'}> 4000 0000 0000 9995 </strong>
            </span>
          </div>
          <div className="w-full border border-transparent mt-5 px-4 text-sm text-txtBlack tablet:text-base font-normal focus:outline-none focus:border rounded-2xl py-5 bg-inputBg2">
            <CardElement
              id="card-element"
              options={cardStyle}
              onChange={handleChange}
            />
          </div>
          {/* Show any error that happens when processing the payment */}
          {error && (
            <div
              className="card-error pl-4 font-medium text-sm mt-2 text-red-600"
              role="alert">
              {error}
            </div>
          )}
        </div>
      )}
      {user?.role !== 'collaborator' && (
        <div
          className={
            checkoutStyle['containerWhite'] +
            `flex-row items-center gap-4 ${!payLater ? ' mt-10' : ' mt-0'}`
          }>
          <CustomCheckbox
            name="pay-later"
            checked={payLater}
            onClick={() => setPayLater(!payLater)}
          />
          <p className="text-sm font-medium">Pay later</p>
        </div>
      )}
      <h2 className="text-left mt-12 pl-2 ">Billing Information</h2>
      <div
        className={
          checkoutStyle['containerWhite'] + 'flex-row items-center gap-4 mt-10'
        }>
        <CustomCheckbox
          name="same-as-delivery"
          checked={deliveryIsBilling}
          onClick={() => setDeliveryIsBilling(!deliveryIsBilling)}
        />
        <p className="text-sm font-medium">Same as delivery information</p>
      </div>
      {!deliveryIsBilling && (
        <div className={checkoutStyle['containerWhite'] + 'flex-col  mt-10'}>
          <h3 className={'pl-2 text-left'}>Billing Address</h3>
          <Input
            classOverride
            className={checkoutStyle['textInput']}
            type="text"
            name={'address'}
            placeholder={'Address'}
            value={address?.address}
            onChange={e => handleInputChange(e)}
            required={true}
          />
          <CustomSelect
            stateList={CountryList}
            value={address?.state}
            name="State"
            handleChange={e => handleSelectChange(e, 'state')}
          />
          <div className=" flex xl:flex-row gap-4">
            <CustomSelect
              citiesList={selectedState.cities}
              value={address?.city}
              name="City"
              handleChange={e => handleSelectChange(e, 'city')}
            />
            <div className="w-2/3">
              <Input
                classOverride
                className={checkoutStyle['textInput']}
                name="zip"
                type="text"
                value={address?.zip}
                placeholder="Zip"
                required={true}
                onChange={e => handleInputChange(e)}
              />
            </div>
          </div>
        </div>
      )}
      <div className="w-full mb-10 desktop:mb-0  mt-14 max-w-lg flex justify-center">
        <Button
          disabled={handleButtonDisabled()}
          isLoading={isLoading}
          type={'submit'}
          title="Confirm"
        />
      </div>
    </form>
  )
}
export default PaymentMethod
