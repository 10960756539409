import React, { useContext } from 'react'
import { Redirect, withRouter } from 'react-router'
import LoginForm from '../../components/forms/loginForm/LoginForm'
import { loginStyles } from '../../theme/theme'
import MobileFooter from '../../components/mobileFooter/MobileFooter'
import { AuthContext } from '../../services/context/authContext/AuthContext'

const Login = () => {
  const user = useContext(AuthContext)

  if (user) {
    return <Redirect to="/" />
  }

  return (
    <div className={loginStyles['background']}>
      <div className={loginStyles['cardContainer']}>
        <div className={loginStyles['formContainer']}>
          <LoginForm />
        </div>
      </div>
      <MobileFooter />
    </div>
  )
}

export default withRouter(Login)
