import React, { useState } from 'react'
import Input from '../../input/Input'
import Button from '../../button/Button'
import { functions } from '../../../services/firebaseConfig'
import { useHistory } from 'react-router'

interface IUserCred {
  email: string
  password: string
  company: string
  firstname: string
  lastname: string
}
const defaultValues = {
  email: '',
  password: '',
  company: '',
  firstname: '',
  lastname: ''
}
const SignupForm = () => {
  const history = useHistory()
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [values, setValues] = useState(defaultValues)
  const [isLoading, setIsLoading] = useState(false)
  const handleInputChange = (element: string, value: string) => {
    setValues({ ...values, [element]: value })
  }
  const handleSignUp = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault()
    setIsLoading(true)
    const { email, password, firstname, lastname, company }: IUserCred = values
    const createNewUser = functions.httpsCallable('createNewUser')
    if (email && password && firstname && lastname && company) {
      await createNewUser({
        email: email,
        password: password,
        firstname: firstname,
        lastname: lastname,
        company: company
      }).then(result => {
        const error = result.data.errorInfo
        if (error) {
          setError(error.message)
          setIsLoading(false)
        } else {
          setSuccess(
            'User created successfully, you can login to your account as soon as an admin will approve your request.'
          )
          setIsLoading(false)
        }
      })
    } else {
      setError('Please complete all fields.')
      setIsLoading(false)
    }
  }
  const successMsg = () => {
    return (
      <div className="w-80 flex flex-col justify-center items-center h-80">
        <p className=" text-green-800 text-center font-bold text-sm tablet:text-lg">
          {success}
        </p>
        <span
          className="text-sm  mt-5 w-80 cursor-pointer underline text-center font-bold"
          onClick={() => history.push('/login')}>
          login
        </span>
        {/*{setTimeout(() =>history.push('/login'), 5000)}*/}
      </div>
    )
  }
  return !success ? (
    <form className="flex flex-col items-center" onSubmit={handleSignUp}>
      <h2 className="font-bold text-txtBlack text-2xl ">
        Signup to G7 Studios
      </h2>
      <Input
        className="mt-12"
        required
        value={values.firstname}
        label="Firstname"
        name="firstname"
        type="text"
        placeholder="Firstname"
        onChange={e => handleInputChange('firstname', e.target.value)}
      />
      <Input
        value={values.lastname}
        className="mt-5"
        required
        label="Lastname"
        name="lastname"
        type="text"
        placeholder="Lastname"
        onChange={e => handleInputChange('lastname', e.target.value)}
      />
      <Input
        value={values.company}
        className="mt-5"
        required
        label="Company"
        name="company"
        type="text"
        placeholder="Company"
        onChange={e => handleInputChange('company', e.target.value)}
      />
      <Input
        className="mt-5"
        value={values.email}
        required
        label="Email"
        name="email"
        type="email"
        placeholder="Email"
        onChange={e => handleInputChange('email', e.target.value)}
      />
      <div className="relative flex items-center  mt-5">
        <Input
          value={values.password}
          className="pr-12"
          icon
          required
          label="Password"
          name="password"
          type="password"
          autoComplete="current-password"
          placeholder="Password"
          onChange={e => handleInputChange('password', e.target.value)}
        />
      </div>
      {error && (
        <span className="text-sm w-80 px-2 text-red-700 mt-5 text-center">
          {error}
        </span>
      )}
      <Button
        isLoading={isLoading}
        className="mt-16"
        title="Sign Up"
        type="submit"
      />
    </form>
  ) : (
    successMsg()
  )
}

export default SignupForm
