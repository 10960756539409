import React, { Dispatch, SetStateAction } from 'react'
import { sortListComponent } from '../../../theme/theme'

interface ISortListComponent {
  items: string[]
  item: string
  setItem: Dispatch<SetStateAction<string>>
}
const SortListComponent = (props: ISortListComponent) => {
  const { items, item, setItem } = props
  return (
    <div className="flex flex-col mx-0 flex-wrap tablet:mx-0  tablet:flex-row gap-5 mt-16 tablet:items-center ">
      <div>
        <h3 className="text-left tablet:text-center">Sort by</h3>
      </div>
      <div className="flex flex-wrap gap-4">
        {items.map(listItem => {
          return (
            <div
              key={listItem}
              className={
                listItem === item
                  ? sortListComponent['sortItemSelected']
                  : sortListComponent['sortItem']
              }
              onClick={() => setItem(listItem)}>
              {listItem}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SortListComponent
