import React, { ChangeEvent } from 'react'

interface ICustomInputDiv {
  isEdit: boolean
  color: string
  value: number
  name: string
  handleChange(e: ChangeEvent<HTMLInputElement>): void
}
const CustomInputDiv = (props: ICustomInputDiv) => {
  const { color, value, name, isEdit, handleChange } = props
  if (isEdit) {
    return (
      <div>
        <input
          type="number"
          name={name}
          className={` focus:outline-none text-center justify-center font-bold text-${color} w-24 h-11 border-2 border-${color} rounded-md`}
          value={value}
          onChange={e => handleChange(e)}
        />
        {isEdit && (
          <span className={`text-${color} font-bold`}>
            {name === 'discount' ? ' %' : ' $'}
          </span>
        )}
      </div>
    )
  } else
    return (
      <div
        className={`flex items-center justify-center font-bold text-white w-24 h-11 bg-${color} rounded-md`}>
        {value} {name === 'discount' ? ' %' : ' $'}
      </div>
    )
}
export default CustomInputDiv
