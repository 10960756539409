import React, { useCallback, useContext, useState } from 'react'
import Input from '../../input/Input'
import Button from '../../button/Button'
import { app } from '../../../services/firebaseConfig'
import { AuthContext } from '../../../services/context/authContext/AuthContext'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'

const IError = {
  msgCode: '',
  msg: ''
}
const LoginForm = () => {
  const [errorMsg, setErrorMsg] = useState(IError)
  const [isLoading, setIsLoading] = useState(false)
  const handleLogin = useCallback(async event => {
    setIsLoading(true)
    event.preventDefault()
    const { email, password } = event.target.elements
    try {
      await app.auth().signInWithEmailAndPassword(email.value, password.value)
      setIsLoading(false)
      setErrorMsg({ msgCode: '', msg: '' })
    } catch (error: any) {
      setErrorMsg({
        msgCode: error.code,
        msg: error.message
      })
      setIsLoading(false)
    }
  }, [])

  const user = useContext(AuthContext)

  if (user) {
    return <Redirect to="/" />
  }

  return (
    <form className="flex flex-col items-center" onSubmit={handleLogin}>
      <h2 className=" text-txtBlack text-lg tablet:text-titleSize ">
        Login to your account
      </h2>
      <Input
        required={true}
        className="mt-12"
        label="Email"
        type="email"
        name="email"
        placeholder="Username"
        autoComplete="email"
      />
      <Input
        required={true}
        className="mt-5"
        label="Password"
        name="password"
        type="password"
        placeholder="Password"
        autoComplete="current-password"
      />
      {errorMsg.msg && errorMsg.msgCode === 'auth/user-disabled' ? (
        <div className="w-80 text-center mt-5 text-red-700 text-sm ">
          Your account is currently disabled, you will be able to log in as soon
          as an admin approves it.
        </div>
      ) : (
        <div className="w-80 text-center mt-5 text-red-700 text-sm ">
          {errorMsg.msg}
        </div>
      )}
      <Button
        isLoading={isLoading}
        className="mt-16 "
        type="submit"
        title="Log in"
      />
      <span className=" text-sm tablet:text-base mt-10 text-txtGray font-medium ">
        Or you can sign up{' '}
        <Link className={'underline cursor-pointer'} to="/signup">
          here
        </Link>
      </span>
      <Link
        to="/forgot-password"
        className="text-sm tablet:text-base cursor-pointer mt-10  text-txtGray font-medium ">
        I forgot my password...
      </Link>
    </form>
  )
}

export default LoginForm
