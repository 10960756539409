import React from 'react'
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import logo from '../../assets/images/png/logo.png'
import moment from 'moment'
import fontBold from '../../assets/fonts/PlusJakartaSans-Bold.woff'
import fontNormal from '../../assets/fonts/PlusJakartaSans-Regular.woff'
import fontMedium from '../../assets/fonts/PlusJakartaSans-Medium.woff'

Font.register({
  family: 'Sans',
  fonts: [
    {
      src: fontNormal,
      fontWeight: 'normal',
      fontStyle: 'normal'
    },
    { src: fontBold, fontWeight: 'bold', fontStyle: 'normal' },
    {
      src: fontMedium,
      fontWeight: 'medium',
      fontStyle: 'normal'
    }
  ]
})
type Order = {
  id: string
  invoiceId: string
  billingAddress: {
    address: string
    city: string
    state: string
    zip: string
  }
  company: string
  createdAt: any
  createdBy: string
  deliveryDate: Date | undefined | string
  pickupDate: Date | undefined | string
  isTaxable: boolean
  isTouchUpKit: boolean
  products: any[]
  subtotal: number
  touchUpKit: number
  totalPrice: number
  assembleFee: number
}
interface IPdfContent {
  order: Order
}
const styles = StyleSheet.create({
  info: {
    fontSize: 8,
    color: 'gray',
    textAlign: 'left',
    paddingVertical: 2
  },
  page: {
    paddingTop: 40,
    paddingHorizontal: 20,
    paddingBottom: 50,
    fontFamily: 'Sans'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 'auto'
  },
  pageNumbers: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    fontSize: 12,
    color: 'gray',
    textAlign: 'center'
  },
  cell: {
    width: '100%',
    height: 'auto',
    fontSize: 9,
    fontWeight: 'medium',
    textAlign: 'center'
  },
  totalsCell: {
    padding: 4,
    width: '100%',
    height: 'auto',
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    borderBottom: '1px solid gray',
    borderRight: '1px solid gray',
    margin: 0
  },
  th: {
    width: '100%',
    height: 'auto',
    fontSize: 11,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  cellContainer: {
    paddingVertical: 5,
    paddingHorizontal: 3,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid gray',
    borderRight: '1px solid gray',
    margin: 0
  },
  totalsContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0
  },
  thContainer: {
    paddingVertical: 5,
    paddingHorizontal: 2,
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid gray',
    borderRight: '1px solid gray',
    margin: 0
  },

  leftContainer: {
    width: '70%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  rightContainer: {
    width: '30%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  subtitles: {
    padding: 5,
    fontFamily: 'Sans',
    fontWeight: 'normal',
    fontSize: 12,
    textAlign: 'left'
  },
  boldSubtitle: {
    minWidth: 140,
    textAlign: 'left',
    fontFamily: 'Sans',
    fontSize: 12,
    fontWeight: 'bold'
  }
})

const PdfContent = (props: IPdfContent) => {
  const { order } = props
  const { address, state, city, zip } = order.billingAddress
  return (
    <Document>
      <Page style={styles.page} size="A4" wrap>
        <View style={styles.logoContainer}>
          <View style={styles.leftContainer}>
            <Text style={styles.subtitles}>
              <Text style={styles.boldSubtitle}>Invoice id:</Text>
              {order.invoiceId}
            </Text>
            <Text style={styles.subtitles}>
              <Text style={styles.boldSubtitle}>Invoice Date:</Text>
              {order.createdAt
                ? moment(order.createdAt.toDate()).format('MMMM-DD-YYYY')
                : moment().format('MMMM-DD-YYYY')}
            </Text>
            <Text style={styles.subtitles}>
              <Text style={styles.boldSubtitle}>Bill to:</Text>
              {order.company}
            </Text>
            <Text style={styles.subtitles}>
              <Text style={styles.boldSubtitle}>Address:</Text>
              {address + ', ' + city + ', ' + state + ', ' + zip}
            </Text>
          </View>
          <View style={styles.rightContainer}>
            <Image style={{ width: 165 }} src={logo} />
          </View>
        </View>

        <View
          style={{
            width: '100%',
            borderTop: '1px solid gray',
            marginTop: 20
          }}>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row'
            }}>
            <View
              style={[
                styles.thContainer,
                { width: '6%', borderLeft: '1px solid gray' }
              ]}>
              <Text style={styles.th}>Qty.</Text>
            </View>
            <View style={[styles.thContainer, { width: '11%' }]}>
              <Text style={styles.th}>SKU</Text>
            </View>
            <View style={[styles.thContainer, { width: '35%' }]}>
              <Text style={styles.th}>Description</Text>
            </View>
            <View style={[styles.thContainer, { width: '6%' }]}>
              <Text style={styles.th}>Style</Text>
            </View>
            <View style={[styles.thContainer, { width: '10%' }]}>
              <Text style={styles.th}>Custom size</Text>
            </View>
            <View style={[styles.thContainer, { width: '9%' }]}>
              <Text style={styles.th}>Drawer</Text>
            </View>
            <View style={[styles.thContainer, { width: '11%' }]}>
              <Text style={styles.th}>Price /Unit</Text>
            </View>
            <View style={[styles.thContainer, { width: '12%' }]}>
              <Text style={styles.th}>Total</Text>
            </View>
          </View>
          {order.products.map((product, index) => {
            return (
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row'
                }}
                key={product.sku + index}
                wrap={false}>
                <View
                  style={[
                    styles.cellContainer,
                    { width: '6%', borderLeft: '1px solid gray' }
                  ]}>
                  <Text style={styles.cell}>{product.qty}</Text>
                </View>
                <View style={[styles.cellContainer, { width: '11%' }]}>
                  <Text style={styles.cell}>{product.sku}</Text>
                </View>
                <View style={[styles.cellContainer, { width: '35%' }]}>
                  <Text style={styles.cell}>
                    {product.name + ' ' + product.description}
                  </Text>
                </View>
                <View style={[styles.cellContainer, { width: '6%' }]}>
                  <Text style={styles.cell}>{product.type?.split(' ')[0]}</Text>
                </View>
                <View style={[styles.cellContainer, { width: '10%' }]}>
                  <Text style={styles.cell}>
                    {product.isCustom ? 'Yes' : '-'}
                  </Text>
                </View>
                <View style={[styles.cellContainer, { width: '9%' }]}>
                  <Text style={styles.cell}>
                    {product.drawer === 'Yes' ? product.drawer : '-'}
                  </Text>
                </View>
                <View style={[styles.cellContainer, { width: '11%' }]}>
                  <Text style={styles.cell}>
                    ${Number(product.price).toFixed(2)}
                  </Text>
                </View>
                <View style={[styles.cellContainer, { width: '12%' }]}>
                  <Text style={styles.cell}>
                    ${(product.price * product.qty).toFixed(2)}
                  </Text>
                </View>
              </View>
            )
          })}
        </View>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row'
          }}
          wrap={false}>
          <View
            style={[
              styles.totalsContainer,
              { width: '58%', borderRight: '1px solid gray' }
            ]}>
            <Text style={styles.info}>
              *the delivery date will be estimated after the order confirmation
            </Text>
            <Text style={styles.info}>
              *the shipping charge will be calculated after the order
              confirmation
            </Text>
          </View>
          <View style={[styles.totalsContainer, { width: '19%' }]}>
            <Text style={styles.totalsCell}>Subtotal</Text>
            <Text style={styles.totalsCell}>Touch Up Kit</Text>
            <Text style={styles.totalsCell}>Assembly fee</Text>
            <Text style={styles.totalsCell}>Shipping charge</Text>
            <Text style={styles.totalsCell}>Sales Tax</Text>
            <Text style={styles.totalsCell}>Total</Text>
          </View>
          <View style={[styles.totalsContainer, { width: '23%' }]}>
            <Text style={styles.totalsCell}>${order.subtotal.toFixed(2)}</Text>
            <Text style={styles.totalsCell}>
              {order.isTouchUpKit ? '$' + order.touchUpKit : '-'}
            </Text>
            <Text style={styles.totalsCell}>
              {order.assembleFee ? '$' + order.assembleFee.toFixed(2) : '-'}
            </Text>
            <Text style={styles.totalsCell}>TBA</Text>
            <Text style={styles.totalsCell}>
              {order.isTaxable ? 'Yes' : 'No'}
            </Text>
            <Text style={styles.totalsCell}>
              ${order.totalPrice.toFixed(2)}
            </Text>
          </View>
        </View>
        <Text
          style={styles.pageNumbers}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  )
}

export default PdfContent
