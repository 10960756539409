import React from 'react'
import Input from '../../input/Input'
import { usersStyle } from '../../../theme/theme'

interface IUserListInputs {
  type: 'discount' | 'status' | 'userType'
  isEdit: boolean
  value: any
  handleChange: (e: any) => void
  name?: string
}

const UserListInputs = (props: IUserListInputs) => {
  const { type, isEdit, value, handleChange, name } = props
  const setClassNames = () => {
    if (type === 'discount') {
      if (!value && !isEdit) {
        return usersStyle['valueDisabled']
      } else {
        return isEdit ? usersStyle['discountEdit'] : usersStyle['discount']
      }
    } else if (type === 'status') {
      return value ? usersStyle['statusConfirmed'] : usersStyle['statusPending']
    } else if (type === 'userType') {
      if (value === 'employee') {
        return isEdit ? usersStyle['employeeEdit'] : usersStyle['employee']
      } else if (value === 'collaborator') {
        return isEdit
          ? usersStyle['collaboratorEdit']
          : usersStyle['collaborator']
      } else if (value === 'accountant') {
        return isEdit ? usersStyle['accountantEdit'] : usersStyle['accountant']
      } else if (value === 'admin') {
        return usersStyle['valueDisabled']
      } else return usersStyle['valueDisabled']
    }
  }

  if (isEdit && type !== 'status') {
    if (type === 'userType') {
      if (value === 'admin') {
        return <div className={setClassNames()}>{value}</div>
      }
      const renderOptions = () => {
        if (!value) {
          return <option value="">select type</option>
        }
      }

      return (
        <select
          style={{ textAlignLast: 'center' }}
          className={setClassNames()}
          value={value}
          name={name}
          onChange={handleChange}>
          {renderOptions()}
          <option value="collaborator">collaborator</option>
          <option value="employee">employee</option>
          <option value="accountant">accountant</option>
        </select>
      )
    } else {
      return (
        <Input
          name={name}
          type={type === 'discount' && isEdit ? 'number' : 'text'}
          value={value}
          onChange={handleChange}
          classOverride={true}
          className={setClassNames()}
        />
      )
    }
  } else if (type === 'status') {
    return (
      <div className={setClassNames()}>
        {value === true ? 'Confirmed' : 'Pending'}
      </div>
    )
  } else {
    return (
      <div className={setClassNames()}>
        {type === 'discount'
          ? value
            ? value + ' %'
            : 'n/a'
          : type === 'userType'
          ? value
            ? value
            : 'n/a'
          : value}
      </div>
    )
  }
}

export default UserListInputs
