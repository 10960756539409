import React from 'react'
import { modalStyles, toasterStyle } from '../../theme/theme'
import Button from '../button/Button'
type User = {
  id: string
  createdAt: any
  company: string
  email: string
  firstname: string
  lastname: string
  discount: number
  disabled: boolean
  role: string
}
interface IToaster {
  modalVisible: boolean
  setModalVisible: (val: boolean) => void
  action?: (user: User, type: 'delete' | 'update' | '') => void
  type: 'delete' | 'update' | ''
  user?: User
}
const Toaster = (props: IToaster) => {
  const { modalVisible, type, action, setModalVisible, user } = props

  return (
    <div
      className={
        modalVisible ? modalStyles['overlayShow'] : modalStyles['overlayHide']
      }>
      <div className={toasterStyle['container']}>
        <div className={toasterStyle['titleContainer']}>
          <h2 className={toasterStyle['title']}>
            {type === 'delete' ? 'Are you sure?' : 'Confirm changes'}
          </h2>
        </div>
        <div className={'p-5 tablet:p-12'}>
          {type === 'delete' ? (
            <div className={toasterStyle['text']}>
              If you delete user{' '}
              <span className={'text-danger break-words'}>{user?.email}</span>{' '}
              you will not be able to undo this action and it will be
              permanently deleted
            </div>
          ) : (
            <div
              className={
                'pb-12 text-center leading-10 text-lg tablet:text-titleSize font-bold '
              }>
              Do you confirm the changes you have made for user {user?.email}?
            </div>
          )}
          <div className={'flex justify-between gap-10'}>
            <Button
              styleOverride={type !== 'delete'}
              onClick={() => setModalVisible(false)}
              className={
                type === 'delete' ? 'max-w-10' : toasterStyle['cancelBtn']
              }
              secondaryType={false}
              title={'Cancel'}
            />
            <Button
              styleOverride={type === 'delete'}
              onClick={() => {
                if (user) {
                  setModalVisible(false)
                  action
                    ? action(user, type)
                    : console.log('no action specified')
                }
              }}
              className={
                type === 'delete' ? toasterStyle['deleteBtn'] : 'max-w-10'
              }
              title={type === 'delete' ? 'Delete' : 'Confirm'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Toaster
