import React, { ChangeEvent, useEffect, useState } from 'react'
import { ReactComponent as Pencil } from '../../assets/images/svg/pencil.svg'
import Button from '../../components/button/Button'
import { db } from '../../services/firebaseConfig'
import CustomInputDiv from '../../components/customSettingsFields/CustomInputDiv'

interface Data {
  cabinetAssembleFee: number
  customCabinetAssembleFee: number
  discount: number
  email: string
  pantryAssembleFee: number
  touchUpKit: number
}
const defaultData = {
  cabinetAssembleFee: 0,
  customCabinetAssembleFee: 0,
  discount: 0,
  email: '',
  pantryAssembleFee: 0,
  touchUpKit: 0
}
const Settings = () => {
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<Data>(defaultData)
  const [compareData, setCompareData] = useState<Data>(defaultData)
  const [reload, setReload] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = () => {
      const ref = db.collection('dashboard').doc('dashboardVariables')
      ref.onSnapshot(snapshot => {
        const data = snapshot.data()
        setData(data as Data)
        setCompareData(data as Data)
      })
    }
    fetchData()
  }, [reload])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  const toggleEdit = () => {
    setIsEdit(!isEdit)
  }
  const handleDisable = () => {
    const { pantryAssembleFee, cabinetAssembleFee, discount } = data
    if (pantryAssembleFee && cabinetAssembleFee && discount) {
      return data === compareData
    } else return true
  }
  const cancelEdit = () => {
    setReload(!reload)
    toggleEdit()
  }
  const updateDashboardVariables = async () => {
    setIsLoading(true)
    const {
      cabinetAssembleFee,
      customCabinetAssembleFee,
      discount,
      email,
      pantryAssembleFee,
      touchUpKit
    } = data
    const docRef = db.collection('dashboard').doc('dashboardVariables')
    await docRef
      .update({
        customCabinetAssembleFee: Number(customCabinetAssembleFee),
        cabinetAssembleFee: Number(cabinetAssembleFee),
        discount: Number(discount),
        email: email,
        pantryAssembleFee: Number(pantryAssembleFee),
        touchUpKit: Number(touchUpKit)
      })
      .then(() => {
        setReload(!reload)
        setIsEdit(false)
        setIsLoading(false)
        console.log('settings updated with success')
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }
  return (
    <div className=" px-5 py-5 tablet:px-10 tablet:py-10 select-none xl:px-24">
      <h1 className="flex items-center gap-6">
        Settings
        <span
          onClick={toggleEdit}
          className="w-10 h-10 rounded-full flex items-center justify-center bg-white cursor-pointer">
          <Pencil />
        </span>
      </h1>
      <div className={'flex gap-10 mt-20 items-start flex-wrap'}>
        <div
          className={
            'flex flex-col gap-7 w-21rem rounded-2xl bg-white px-10 py-14'
          }>
          <h2 className="text-center mb-3">Assemble fee</h2>
          <div className="flex w-full justify-between items-center ">
            <h2>Cabinet</h2>
            <CustomInputDiv
              color="primary"
              value={data.cabinetAssembleFee}
              name="cabinetAssembleFee"
              isEdit={isEdit}
              handleChange={handleChange}
            />
          </div>
          <div className="flex w-full justify-between items-center">
            <h2>Pantry</h2>
            <CustomInputDiv
              color="primary"
              value={data.pantryAssembleFee}
              name="pantryAssembleFee"
              isEdit={isEdit}
              handleChange={handleChange}
            />
          </div>
          <div className="flex w-full justify-between items-center">
            <h2>Custom cab.</h2>
            <CustomInputDiv
              color="primary"
              value={data.customCabinetAssembleFee}
              name="customCabinetAssembleFee"
              isEdit={isEdit}
              handleChange={handleChange}
            />
          </div>
          <hr className="my-5" />
          <h2 className="text-center mb-3">Other</h2>
          <div className="flex w-full justify-between items-center">
            <h2>Touch up kit</h2>
            <CustomInputDiv
              color="primaryYellow"
              value={data.touchUpKit}
              name="touchUpKit"
              isEdit={isEdit}
              handleChange={handleChange}
            />
          </div>
        </div>

        <div
          className={
            'w-29rem flex  flex-col gap-7 rounded-2xl bg-white px-10 py-14'
          }>
          <h2 className=" mb-3">Your email address</h2>
          {isEdit ? (
            <input
              type="email"
              value={data.email}
              name="email"
              className="relative font-medium focus:outline-none h-14 px-5 w-72 object-contain bg-inputBg text-sm rounded-2xl"
              onChange={handleChange}
            />
          ) : (
            <div className="flex items-center h-14 font-medium rounded-2xl bg-bgDefault px-5">
              {data.email}
            </div>
          )}
          <h2 className=" mb-3">Your discount</h2>
          <CustomInputDiv
            color="success"
            value={data.discount}
            name="discount"
            isEdit={isEdit}
            handleChange={handleChange}
          />
        </div>
      </div>
      {isEdit && (
        <div className={'w-full flex justify-center gap-10 mt-14'}>
          <Button
            className={''}
            disabled={handleDisable()}
            isLoading={isLoading}
            title={'Save changes'}
            onClick={() => updateDashboardVariables()}
          />
          <Button
            onClick={() => cancelEdit()}
            styleOverride={true}
            className={
              'max-w-10 transform border-2 border-primary text-primary cursor-pointer bg-transparent' +
              ' text-sm min-h-button-h tablet:text-base ' +
              'focus:outline-none active:scale-95 font-bold w-60  h-14  rounded-2xl '
            }
            secondaryType={true}
            title={'Cancel'}
          />
        </div>
      )}
    </div>
  )
}

export default Settings
