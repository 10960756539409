import React, { useContext, useEffect, useState } from 'react'
import { ReactComponent as Checkmark } from '../../assets/images/svg/checkmark.svg'
import { AuthContext } from '../../services/context/authContext/AuthContext'
import Button from '../button/Button'
import { useLocation } from 'react-router-dom'
import { withRouter } from 'react-router'
import { db } from '../../services/firebaseConfig'
import { pdf, PDFDownloadLink } from '@react-pdf/renderer'
import PdfContent from '../pdf/PdfContent'

const OrderConfirm = () => {
  const user = useContext(AuthContext)

  const [order, setOrder] = useState<any>()
  const { state } = useLocation()
  // @ts-ignore
  const orderId = state?.orderId

  useEffect(() => {
    const getOrder = async () => {
      const response = db.collection('orders').doc(orderId)
      const doc = await response.get()
      if (doc.exists) {
        const data = doc.data()
        setOrder(data)
      } else {
        console.log('error getting document data, doc doesnt exist.')
      }
    }
    getOrder().catch(err => {
      console.log(err)
    })
  }, [orderId])

  const previewPdf = async () => {
    const instance = pdf()
    instance.updateContainer(<PdfContent order={order} />)
    const blob = await instance.toBlob()
    const reader = new window.FileReader()
    reader.readAsArrayBuffer(blob)
    reader.onloadend = function () {
      const arrayBufferData = reader.result && reader.result
      const file =
        arrayBufferData &&
        new Blob([arrayBufferData], { type: 'application/pdf' })
      if (file) {
        const fileUrl = URL.createObjectURL(file)
        window.open(fileUrl, '_blank')
      }
    }
  }
  return (
    <div className=" px-10 tablet:px-24">
      <h2 className="mb-14 tablet:mb-28">Order Confirmation</h2>
      <div className=" p-5 tablet:p-11 bg-white rounded-2xl flex flex-col">
        <h3 className="flex gap-3">
          Your order has been sent!
          <Checkmark className="w-7 h-7" />
        </h3>
        <p className="mt-6 font-medium text-base">
          An email has been sent to <strong>{user && user.email}</strong>.
          Please check your inbox for more details. You will receive more
          details when the order will be confirmed.
        </p>
        <p className="mt-12 font-medium text-base">
          You can download the invoice in PDF format here:
        </p>
        <div className="mt-7 flex gap-7">
          <Button
            content={
              order && (
                <PDFDownloadLink
                  className={' w-full h-full flex items-center justify-center'}
                  document={<PdfContent order={order} />}
                  fileName={`${order.invoiceId}.pdf`}>
                  {({ loading, error }) => {
                    return loading
                      ? 'Loading pdf...'
                      : error
                      ? 'Error..'
                      : 'Download Pdf'
                  }}
                </PDFDownloadLink>
              )
            }
          />
          <Button
            title={'Preview pdf'}
            onClick={() => {
              previewPdf().catch(err => console.log(err))
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default withRouter(OrderConfirm)
