import React from 'react'

interface ICustomCheckbox {
  classOverride?: boolean
  className?: string
  name: string
  checked: boolean
  onClick(value: string): void
}

function CustomCheckbox(props: ICustomCheckbox) {
  const { name, checked, onClick } = props
  return (
    <div
      className="w-6 h-6 min-w-6 cursor-pointer rounded-full border border-txtGray"
      onClick={() => onClick(name)}>
      <div
        className={
          checked
            ? 'bg-black w-full h-full transform scale-75 bg-gradient-to-r from-btnStart to-btnEnd rounded-full'
            : ''
        }
      />
    </div>
  )
}

export default CustomCheckbox
