import React from 'react'
import { orderHistory } from '../../../theme/theme'
interface IListItem {
  item: {
    id: string
    invoiceId: string
    paymentType: string
    company: string
    totalPrice: number
    products: [{ qty: number }]
    isPayed: boolean
    deliveryDate: string | Date | undefined
    pickupDate: string | Date | undefined
  }
  index: number
  setCurrentIndex: (i: number) => void
  toggleModal: () => void
}
const ListItem = (props: IListItem) => {
  const { item, index, setCurrentIndex, toggleModal } = props
  let items: number = 0
  return (
    <div
      className={orderHistory['listItemContainer']}
      key={item.id}
      onClick={() => {
        setCurrentIndex(index)
        toggleModal()
      }}>
      <h2 className="text-txtGray font-medium tablet:font-bold tablet:text-txtBlack tablet:w-20 desktop:w-30 xl:w-32 text-base tablet:text-sm desktop:text-xl xl:text-titleSize">
        {item.invoiceId}
      </h2>
      <h2 className="text-left tablet:text-center tablet:w-68 desktop:w-80 text-base desktop:text-xl xl:text-titleSize">
        {item.company}
      </h2>
      <h2 className="tablet:w-20 desktop:w-28 text-base desktop:text-xl xl:text-titleSize">
        x
        {item.products.forEach((item: { qty: number }) => {
          items = Number(items) + Number(item.qty)
        })}
        {items}
      </h2>
      <h2 className="tablet:w-36 text-base desktop:text-xl xl:text-titleSize">
        ${item.totalPrice.toFixed(2)}
      </h2>
      <div className="tablet:w-36 flex justify-center text-center items-center right-5 bottom-8 absolute tablet:static">
        <div
          className={
            item.isPayed
              ? orderHistory['itemPaid']
              : orderHistory['itemNotPaid']
          }>
          {item.isPayed
            ? item.paymentType === 'credit'
              ? 'Credit card'
              : 'Check'
            : 'Unpaid'}
        </div>
      </div>
      <div className=" text-left tablet:text-center tablet:w-36  flex flex-col items-center justify-center">
        {item.deliveryDate ? (
          <p className="w-full text-left tablet:text-center text-xs font-bold">
            Delivery expected by
          </p>
        ) : (
          <p className="w-full text-left tablet:text-center text-xs font-bold">
            Pick up expected by
          </p>
        )}
        {item.deliveryDate ? (
          <h2 className="text-base desktop:text-xl xl:text-titleSize">
            {item.deliveryDate}
          </h2>
        ) : (
          <h2 className="text-base desktop:text-xl xl:text-titleSize">
            {item.pickupDate}
          </h2>
        )}
      </div>
    </div>
  )
}

export default ListItem
