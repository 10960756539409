import { ReactComponent as DeliveryIcon } from '../../assets/images/svg/delivery.svg'
import { OrderContext } from '../../services/context/orderContext/OrderContext'
import { useContext } from 'react'

interface IDelivery {
  toggle: () => void
}
const Delivery = (props: IDelivery) => {
  const { toggle } = props
  const order = useContext(OrderContext)
  return (
    <div className="relative">
      <DeliveryIcon className="cursor-pointer" onClick={toggle} />
      {order.currentOrder.length ? (
        <span className="absolute text-sm font-bold tablet-top-4 right-11 -top-2 tablet:-right-4 px-1 w-auto min-w-5 h-5 flex items-center justify-center rounded-full bg-primary  text-white">
          {order.currentOrder.length}
        </span>
      ) : null}
    </div>
  )
}

export default Delivery
