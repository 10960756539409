import React from 'react'
import { Link } from 'react-router-dom'

const MobileFooter = () => {
  return (
    <div className="flex flex-col flex-col-reverse -mt-20 mb-16  tablet:hidden items-center  justify-center z-10 text-lg bottom-0 gap-5 font-bold">
      <Link to="/contact">
        <h2 className="text-white text-lg">Contact</h2>
      </Link>
      <Link to="/help">
        <h2 className="text-white text-lg">Help</h2>
      </Link>
    </div>
  )
}

export default MobileFooter
