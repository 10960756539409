import React, { useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { auth } from '../../services/firebaseConfig'
import { menuStyles } from '../../theme/theme'
import CloseButton from '../closeButton/CloseButton'
import { AuthContext } from '../../services/context/authContext/AuthContext'

interface IDrawerMenu {
  toggle: () => void
  showMenu: boolean
}

const DrawerMenu = (props: IDrawerMenu) => {
  const user = useContext(AuthContext)
  const isAdmin = user?.role === 'admin'
  const { toggle, showMenu } = props
  const history = useHistory()
  useEffect(() => {
    if (!showMenu) {
      document.body.setAttribute('style', 'overflow:hidden')
    }
    showMenu && document.body.setAttribute('style', 'overflow:unset')
  }, [showMenu])
  return (
    <>
      <div
        className={!showMenu ? 'overlay' : 'overlay-hide'}
        onClick={toggle}
      />
      <div
        className={showMenu ? menuStyles['menuShow'] : menuStyles['menuHide']}
        onClick={e => {
          e.stopPropagation()
        }}>
        <CloseButton
          className="mt-11 right-9 tablet:mt-16"
          currentColor="black"
          onClick={toggle}
        />
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col items-start ">
            <p className="mb-16 text-sm  mt-28 xl:mt-52 tablet:mt-36 cursor-pointer text-txtGray font-medium text-base tablet:text-lg">
              Menu
            </p>
            {isAdmin ? (
              <Link
                onClick={toggle}
                className="mb-9 cursor-pointer text-txtBlack font-bold text-xl"
                to="/users">
                Users
              </Link>
            ) : (
              <Link
                onClick={toggle}
                className="mb-9 cursor-pointer text-txtBlack font-bold text-xl"
                to="/my-account">
                Account
              </Link>
            )}
            <Link
              onClick={toggle}
              className="mb-9 cursor-pointer text-txtBlack font-bold text-xl"
              to="/orders">
              Orders
            </Link>
            {isAdmin ? (
              <Link
                onClick={toggle}
                className="xl:mb-28 mb-16 cursor-pointer text-txtBlack font-bold text-xl"
                to="/settings">
                Settings
              </Link>
            ) : (
              <Link
                onClick={toggle}
                className="xl:mb-28 mb-16 cursor-pointer text-txtBlack font-bold text-xl"
                to="/help">
                Help
              </Link>
            )}
            <p
              className=" cursor-pointer text-txtBlack font-bold text-xl"
              onClick={() => {
                auth.signOut().catch((err: string) => console.log(err))
                toggle()
                history.push('/')
              }}>
              Log out
            </p>
          </div>
          <div className=" pb-14 tablet:pb-20 mt-10">
            <p className="mb-8 text-txtGray font-medium text-base tablet:text-lg">
              Contact
            </p>
            <p className="mb-2.5 text-base font-medium text-txtBlack">
              +77 777 777 777
            </p>
            <p className="text-base font-medium text-txtBlack">
              office@g7studios.com
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default DrawerMenu
