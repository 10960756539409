import React from 'react'
import { styleDescription } from '../../theme/theme'

const StyleDescription = () => {
  return (
    <div className="w-full flex flex-col gap-10">
      <section className="mt-10">
        <h2 className={styleDescription['title']}>
          Shaker styles cabinets description
        </h2>
        <div className={styleDescription['whiteContainer']}>
          <img
            className={styleDescription['image']}
            src={
              require('../../assets/images/png/styleDescription/shaker-white.png')
                .default
            }
            alt=""
          />
          <div className={styleDescription['textContainer']}>
            <h3 className={styleDescription['subtitle']}>Shaker White</h3>
            <p className={styleDescription['paragraph']}>
              HDF frame with mdf center panel
              <br />
              Mental clip assembly
              <br />
              Full overlay doors&drawers
              <br />
              Under mount full extension soft close drawer glides
              <br />
              Soft close concealed European style hinges
              <br />
              UV coated natural interior
            </p>
          </div>
        </div>

        <div className={styleDescription['whiteContainer']}>
          <img
            className={styleDescription['image']}
            src={
              require('../../assets/images/png/styleDescription/shaker-expresso.png')
                .default
            }
            alt=""
          />
          <div className={styleDescription['textContainer']}>
            <h3 className={styleDescription['subtitle']}>Shaker Espresso</h3>
            <p className={styleDescription['paragraph']}>
              Solid birch frame with mdf center panel <br />
              Metal clip assembly <br />
              Full overlay doors&drawers <br />
              Soft close concealed European style hinges <br />
              1/2"plywood box with pained exterior
            </p>
          </div>
        </div>
        <div className={styleDescription['whiteContainer']}>
          <img
            className={styleDescription['image']}
            src={
              require('../../assets/images/png/styleDescription/shaker-gray.png')
                .default
            }
            alt=""
          />
          <div className={styleDescription['textContainer']}>
            <h3 className={styleDescription['subtitle']}>Shaker Grey</h3>
            <p className={styleDescription['paragraph']}>
              HDF frame with mdf center panel
              <br />
              Mental clip assembly
              <br />
              Full overlay doors&drawers
              <br />
              Under mount full extension soft close drawer glides
              <br />
              Soft close concealed European style hinges
              <br />
              UV coated natural interior
              <br />
              1/2"plywood box with pained exterior
            </p>
          </div>
        </div>
      </section>
      <section>
        <h2 className={styleDescription['title']}>
          Charleston/Aspen styles cabinets description
        </h2>
        <div className={styleDescription['whiteContainer']}>
          <img
            className={styleDescription['image']}
            src={
              require('../../assets/images/png/styleDescription/charleston-white.png')
                .default
            }
            alt=""
          />
          <div className={styleDescription['textContainer']}>
            <h3 className={styleDescription['subtitle']}>Charleston White</h3>
            <p className={styleDescription['paragraph']}>
              Solid birch frame mdf center panel <br />
              Mental clip assembly <br />
              Full overlay doors&drawers <br />
              Soft close concealed European style hinges <br />
              UV coated natural interior <br />
              1/2"plywood box with pained exterior
            </p>
          </div>
        </div>
        <div className={styleDescription['whiteContainer']}>
          <img
            className={styleDescription['image']}
            src={
              require('../../assets/images/png/styleDescription/charleston-saddle.png')
                .default
            }
            alt=""
          />
          <div className={styleDescription['textContainer']}>
            <h3 className={styleDescription['subtitle']}>Charleston Saddle</h3>
            <p className={styleDescription['paragraph']}>
              Solid birch frame with mdf center panel <br />
              Mental clip assembly <br />
              Full overlay doors&drawers <br />
              Under mount full extension soft close drawer glides <br />
              Soft close concealed European style hinges <br />
              UV coated natural interior <br />
              1/2"plywood box with pained exterior
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default StyleDescription
