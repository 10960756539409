import React, { useContext, useEffect, useMemo, useState } from 'react'
import Pagination from '../../pagination/Pagination'
import { pagination } from '../../../theme/theme'
import Modal from '../../modal/Modal'
import { AuthContext } from '../../../services/context/authContext/AuthContext'
import ListItem from './ListItem'
import AdminListItem from './AdminListItem'
import { db } from '../../../services/firebaseConfig'
type Order = {
  id: string
  invoiceId: string
  paymentType: string
  company: string
  createdAt: any
  createdBy: string
  deliveryDate: Date | undefined | string
  pickupDate: Date | undefined | string
  isTouchUpKit: boolean
  products: [{ qty: number }]
  subtotal: number
  touchUpKit: number
  totalPrice: number
  assembleFee: number
  isPayed: boolean
}
interface INameToValueMap {
  [key: string]: any
}
interface IOrderHistoryList {
  orders: Order[]
  isLoading: boolean
}
const OrderHistoryList = (props: IOrderHistoryList) => {
  const user = useContext(AuthContext)

  const [isLoadingItem, setIsLoadingItem] = useState<INameToValueMap>()
  const isAdmin = user?.role === 'admin'
  const isAccountant = user?.role === 'accountant'
  const { orders, isLoading } = props
  const [isOpen, setIsOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [isCheckVisible, setIsCheckVisible] = useState(false)
  // Pagination
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(5)
  // END Pagination

  useEffect(() => {
    if (isOpen) {
      document.body.setAttribute('style', 'overflow:hidden')
    }
    !isOpen && document.body.setAttribute('style', 'overflow:unset')
  }, [isOpen])

  // effect for list to be render after pagination change
  const currentOrderList = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    if (firstPageIndex >= orders.length) {
      return orders.slice(0, orders.length)
    } else {
      return orders.slice(firstPageIndex, lastPageIndex)
    }
  }, [currentPage, pageSize, orders])

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const updateOrder = async (id: string) => {
    setIsLoadingItem({ ...isLoadingItem, [id]: true })
    const ref = db.collection('orders').doc(id)
    await ref
      .update({
        isPayed: true
      })
      .then(() => {
        setIsLoadingItem({ ...isLoadingItem, [id]: false })
      })
  }

  return (
    <div className="mt-20">
      {isAdmin || isAccountant ? (
        <div className="w-full hidden desktop:flex  desktop:text-xl xl:text-titleSize  p-5 xl:p-8 text-center items-center justify-between">
          <h2 className="w-30  desktop:text-xl xl:text-titleSize ">Number</h2>
          <h2 className="w-40 desktop:text-xl xl:text-titleSize ">Date</h2>
          <h2 className="w-52 desktop:text-xl xl:text-titleSize ">user</h2>
          <h2 className="w-60 desktop:text-xl xl:text-titleSize ">Company</h2>
          <h2 className="w-36 desktop:text-xl xl:text-titleSize ">Payment</h2>
          <div className="w-36 desktop:text-xl xl:text-titleSize " />
        </div>
      ) : (
        <div className="w-full hidden desktop:flex  desktop:text-xl xl:text-titleSize  p-5 xl:p-8 text-center items-center justify-between">
          <h2 className="w-30 xl:w-32 desktop:text-xl xl:text-titleSize">
            Number
          </h2>
          <div className="desktop:w-80 desktop:text-xl xl:text-titleSize" />
          <h2 className="w-32 desktop:text-xl xl:text-titleSize">Items</h2>
          <h2 className="w-36 desktop:text-xl xl:text-titleSize">Price</h2>
          <h2 className="w-36 desktop:text-xl xl:text-titleSize">Payment</h2>
          <h2 className="w-36 desktop:text-xl xl:text-titleSize">Delivery</h2>
        </div>
      )}
      <div className="flex flex-col gap-7">
        {orders.length ? (
          currentOrderList.map((item, index) => {
            return isAdmin || isAccountant ? (
              <AdminListItem
                setIsCheckVisible={val => setIsCheckVisible(val)}
                isCheckVisible={isCheckVisible}
                key={item.id}
                item={item}
                index={index}
                isLoading={isLoadingItem}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                toggleModal={toggleModal}
                updateOrder={() => updateOrder(item.id)}
              />
            ) : (
              <ListItem
                key={item.id}
                item={item}
                index={index}
                setCurrentIndex={setCurrentIndex}
                toggleModal={toggleModal}
              />
            )
          })
        ) : isLoading ? (
          <div className="font-bold text-center">loading...</div>
        ) : (
          <div className="font-bold text-center">No orders found.</div>
        )}
      </div>
      {!(pageSize >= orders.length) && (
        <div className={pagination['showMoreContainer']}>
          <div
            className={pagination['showMore']}
            onClick={() => setPageSize(pageSize + 5)}>
            show more
          </div>
        </div>
      )}
      <Pagination
        currentPage={currentPage}
        totalCount={orders.length}
        pageSize={pageSize}
        onPageChange={(page: number) => setCurrentPage(page)}
      />
      {isOpen && (
        <Modal
          title="Order details"
          content="order"
          toggle={toggleModal}
          modalVisible={isOpen}
          products={orders}
          index={currentIndex}
        />
      )}
    </div>
  )
}

export default OrderHistoryList
