import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { functions } from '../../../services/firebaseConfig'
import Pagination from '../../pagination/Pagination'
import { pagination, usersStyle } from '../../../theme/theme'
import { ReactComponent as Trash } from '../../../assets/images/svg/trash.svg'
import { ReactComponent as Cancel } from '../../../assets/images/svg/user-cancel.svg'
import { ReactComponent as Checkmark } from '../../../assets/images/svg/check.svg'
import UserListInputs from '../../customFormElements/userListInputs/UserListInputs'
import moment from 'moment'
import Toaster from '../../toaster/Toaster'

interface IUser {
  id: string
  createdAt: any
  company: string
  email: string
  firstname: string
  lastname: string
  discount: number
  disabled: boolean
  role: string
}
interface INameToValueMap {
  [key: string]: any
}

interface IUserList {
  users: IUser[]
  isLoadingList: boolean
}
const UserList = (props: IUserList) => {
  const { users, isLoadingList } = props

  const [isLoading, setIsLoading] = useState<INameToValueMap>()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)
  const [itemId, setItemId] = useState<string>('')
  const [user, setUser] = useState<IUser>()
  const [type, setType] = useState<'delete' | 'update' | ''>('')
  const [usersCopy, setUsersCopy] = useState([] as any)

  // Pagination
  const [pageSize, setPageSize] = useState<number>(5)
  const [currentPage, setCurrentPage] = useState<number>(1)
  // END Pagination
  useEffect(() => {
    const newUsers = JSON.parse(JSON.stringify(users))
    setUsersCopy(newUsers)
  }, [users, reload])

  const currentUserList = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    if (firstPageIndex >= usersCopy.length) {
      return usersCopy.slice(0, usersCopy.length)
    } else {
      return usersCopy.slice(firstPageIndex, lastPageIndex)
    }
  }, [currentPage, pageSize, usersCopy])

  //effect for hiding scrollbar on body when modal is open
  useEffect(() => {
    if (modalVisible) {
      document.body.setAttribute('style', 'overflow:hidden')
    }
    !modalVisible && document.body.setAttribute('style', 'overflow:unset')
  }, [modalVisible])

  const deleteUser = async (user: IUser) => {
    const { id } = user
    if (id) {
      setIsLoading({ ...isLoading, [id]: true })
      const deleteUser = functions.httpsCallable('deleteUser')
      await deleteUser({
        id: id
      }).then(res => {
        if (res.data.error) {
          setIsLoading({ ...isLoading, [id]: false })
          console.log(res.data.error)
        } else {
          console.log(res.data)
        }
        setIsLoading({ ...isLoading, [id]: false })
        closeEdit()
      })
    }
  }
  const modifyUserClaims = async (user: IUser) => {
    setIsLoading({ ...isLoading, [user.id]: true })
    const updateClaims = functions.httpsCallable('updateClaims')
    await updateClaims({
      id: user.id,
      role: user.role,
      discount: user.discount
    }).then(result => {
      const error = result.data ? result.data.errorInfo : null
      if (error) {
        console.log(error, error.code, error.message)
        setIsLoading({ ...isLoading, [user.id]: false })
        closeEdit()
      } else {
        console.log('User updated with success.', result.data)
        setIsLoading({ ...isLoading, [user.id]: false })
        closeEdit()
      }
    })
  }
  const addUserClaims = async (user: IUser) => {
    setIsLoading({ ...isLoading, [user.id]: true })
    const addClaims = functions.httpsCallable('addClaims')
    await addClaims({
      id: user.id,
      email: user.email,
      role: user.role,
      discount: user.discount
    }).then(result => {
      const error = result.data ? result.data.errorInfo : null
      if (error) {
        console.log(error, error.code, error.message)
        setIsLoading({ ...isLoading, [user.id]: false })
        closeEdit()
      } else {
        console.log('User approved with success.', result.data)
        setIsLoading({ ...isLoading, [user.id]: false })
        closeEdit()
      }
    })
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>, _user: IUser) => {
    const value = e.target.value
    const attr = e.target.name
    const newList = [...usersCopy]
    const index = newList.indexOf(_user)
    const user = { ...newList[index] }
    console.log(value)
    user[attr] = value
    newList[index][attr] = user[attr]
    setUsersCopy(newList)
  }
  const toggleEdit = (user: IUser) => {
    const { id } = user
    if (itemId === '') {
      setIsEdit(true)
      setItemId(id)
      setUser(user)
    }
  }
  const openModal = (type: 'delete' | 'update') => {
    setType(type)
    setModalVisible(true)
  }
  const closeEdit = () => {
    setItemId('')
    setUser(undefined)
    setIsEdit(false)
    setReload(!reload)
  }
  const handleToasterAction = (user: IUser, type: 'delete' | 'update' | '') => {
    if (type === 'delete') {
      deleteUser(user).catch(err => console.log(err))
    } else if (type === 'update') {
      user.disabled ? addUserClaims(user) : modifyUserClaims(user)
    } else {
      return
    }
  }
  const saveEnable = (user: IUser) => {
    const index = usersCopy.indexOf(user)

    if (user.discount && user.role && user.discount > 0) {
      const defaultRole = users[index]?.role
      const defaultDiscount = users[index]?.discount
      return !(
        user.role === defaultRole &&
        Number(user.discount) === Number(defaultDiscount)
      )
    } else return false
  }
  return (
    <div className="mt-20 w-full">
      <div className={usersStyle['thContainer']}>
        <h2 className={usersStyle['thElement'] + ' w-52'}>Email</h2>
        <h2 className={usersStyle['thElement'] + ' w-40'}>Created</h2>
        <h2 className={usersStyle['thElement'] + ' w-80'}>Company</h2>
        <h2 className={usersStyle['thElement'] + ' w-24'}>Discount</h2>
        <h2 className={usersStyle['thElement'] + ' w-36'}>Type</h2>
        <div className={usersStyle['thElement'] + ' w-28'} />
      </div>
      <div className="w-full flex justify-center items-center flex-col gap-7">
        {isLoadingList ? (
          'Loading'
        ) : usersCopy.length ? (
          currentUserList.map((user: IUser, index: number) => {
            return (
              <div
                key={user.id}
                className={
                  isEdit && user.id === itemId
                    ? usersStyle['userContainerEdit']
                    : usersStyle['userContainer']
                }
                onClick={() => toggleEdit(user)}>
                {isLoading?.[user.id] && (
                  <div
                    className={
                      'absolute flex justify-center items-center top-0 right-0 bottom-0 bg-overlay left-0 '
                    }>
                    <div className=" animate-spin  ease-linear rounded-full border-4  h-8 w-8  border-top-color" />
                  </div>
                )}
                <div
                  className={
                    usersStyle['itemElement'] +
                    `${
                      isEdit && user.id === itemId ? ' pr-12 ' : ' pr-0 '
                    } w-full tablet:mr-0 text-left break-all  desktop:w-52`
                  }>
                  {user.email}
                </div>
                <div
                  className={
                    usersStyle['itemElement'] +
                    `${
                      isEdit && user.id === itemId ? ' mr-12 ' : 'mr-0  '
                    } w-full desktop:mr-0 text-left  desktop:w-40 `
                  }>
                  {moment(users[index]?.createdAt.toDate()).format('MM-DD-YY')}
                </div>
                <div
                  className={
                    usersStyle['itemElement'] +
                    `${
                      isEdit && user.id === itemId ? ' mr-12 ' : 'mr-0  '
                    } w-full desktop:mr-0 min-w-48 text-left  desktop:w-80 `
                  }>
                  {user.company}
                </div>
                <div
                  className={usersStyle['itemElement'] + 'w-16 tablet:w-24 '}>
                  <UserListInputs
                    name="discount"
                    handleChange={e => handleChange(e, user)}
                    isEdit={isEdit && itemId === user.id}
                    type="discount"
                    value={user.discount}
                  />
                </div>
                <div
                  className={usersStyle['itemElement'] + 'w-32 tablet:w-36 '}>
                  <UserListInputs
                    name="role"
                    handleChange={e => handleChange(e, user)}
                    isEdit={isEdit && itemId === user.id}
                    type="userType"
                    value={user.role || ''}
                  />
                </div>
                <div
                  className={
                    usersStyle['itemElement'] +
                    `${
                      isEdit && itemId === user.id ? 'mr-12' : 'mr-0'
                    } w-28 desktop:mr-0 `
                  }>
                  <UserListInputs
                    handleChange={e => handleChange(e, user)}
                    isEdit={isEdit && itemId === user.id}
                    type="status"
                    value={!user.disabled}
                  />
                </div>
                <div className="flex flex-col desktop:gap-0 gap-3 justify-between desktop:-top-4 desktop:-bottom-4 absolute right-5 desktop:-right-6">
                  <div
                    onClick={() => {
                      if (saveEnable(user)) {
                        openModal('update')
                      } else console.log('forbidden')
                    }}
                    className={`flex items-center  justify-center 
                        ${
                          isEdit && user.id === itemId
                            ? 'w-10 h-10 xl:w-11  xl:h-11'
                            : 'w-0 h-0 pointer-events-none overflow-hidden '
                        }  rounded-full  origin-center  transition-size duration-300 ${
                      saveEnable(user)
                        ? 'bg-success  transform active:scale-90'
                        : 'bg-txtGray cursor-not-allowed'
                    } `}>
                    <Checkmark className={'text-white'} />
                  </div>
                  <div
                    onClick={() => {
                      if (!isLoading?.[user.id]) {
                        return closeEdit()
                      }
                    }}
                    className={` ${
                      isEdit && user.id === itemId
                        ? 'w-10 h-10 xl:w-11  xl:h-11 border-2 active:scale-90 transform'
                        : 'w-0 h-0 pointer-events-none overflow-hidden border-0 '
                    }  flex items-center justify-center   origin-center transition-size duration-300 rounded-full  border-danger bg-white`}>
                    <Cancel />
                  </div>

                  <div
                    onClick={() => openModal('delete')}
                    className={`${
                      isEdit && user.id === itemId
                        ? 'w-10 h-10 xl:w-11  xl:h-11 transform active:scale-90'
                        : 'w-0 h-0 pointer-events-none overflow-hidden '
                    }  flex items-center justify-center  origin-center transition-size duration-300 rounded-full bg-danger`}>
                    <Trash className="text-white active:scale-90" />
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div className={'font-bold'}>No users found.</div>
        )}
      </div>
      <Toaster
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        type={type}
        user={user}
        action={user => handleToasterAction(user, type)}
      />
      {!(pageSize >= users.length) && (
        <div className={pagination['showMoreContainer']}>
          <div
            className={pagination['showMore']}
            onClick={() => setPageSize(pageSize + 5)}>
            show more
          </div>
        </div>
      )}
      <Pagination
        currentPage={currentPage}
        totalCount={users.length}
        pageSize={pageSize}
        onPageChange={(page: number) => setCurrentPage(page)}
      />
    </div>
  )
}

export default UserList
