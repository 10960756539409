import React, { useContext, useEffect, useState } from 'react'

import Button from '../../button/Button'
import { productStyle } from '../../../theme/theme'
import Modal from '../../modal/Modal'
import { OrderContext } from '../../../services/context/orderContext/OrderContext'

interface IProductList {
  setProducts(products: any[]): void
  products: [any]
}
type stringType = 'edit' | 'details' | ''

const ProductList = (props: IProductList) => {
  const { products, setProducts } = props
  const [toggleModalVisible, setToggleOrderVisible] = useState(false)
  const [index, setIndex] = useState(-1)
  const [modalContent, setModalContent] = useState<stringType>('')
  const add = useContext(OrderContext)
  useEffect(() => {
    if (toggleModalVisible) {
      document.body.setAttribute('style', 'overflow:hidden')
    }
    !toggleModalVisible && document.body.setAttribute('style', 'overflow:unset')
  }, [toggleModalVisible])

  const toggle = () => {
    setToggleOrderVisible(!toggleModalVisible)
  }
  const handleModalProps = (index: number, content: stringType) => {
    setIndex(index)
    setModalContent(content)
    toggle()
  }
  const addProduct = (product: {}, type: 'add' | 'edit') => {
    add.addToOrder(product, type)
  }

  return (
    <div className="   tablet:w-full flex flex-col gap-8 tablet:gap-16 my-12">
      {products &&
        products.map((product: any, index: number) => {
          if (product) {
            return (
              <div key={product.sku} className={productStyle['product']}>
                <div className="flex w-full flex-col tablet:flex-row justify-items-stretch tablet:items-center">
                  <div className={productStyle['flexItemLeft']}>
                    <h2 className={productStyle['grayTitleSku']}>
                      {product.sku}
                    </h2>
                    <h2 className={productStyle['attributes']}>
                      {product.name}
                    </h2>
                    <span className={productStyle['description']}>
                      {product.description}
                    </span>
                  </div>
                  <div className={productStyle['diagonalLine']} />
                  <div className={productStyle['flexItem']}>
                    <h2 className={productStyle['grayTitle']}>Weight</h2>
                    <h2 className={productStyle['attributesHidden']}>
                      {product.weight}
                    </h2>
                  </div>
                  <div className={productStyle['diagonalLine']} />
                  <div className={productStyle['flexItem']}>
                    <h2 className={productStyle['grayTitle']}>Cubes</h2>
                    <h2 className={productStyle['attributesHidden']}>
                      {product.cubes}
                    </h2>
                  </div>
                  <div className={productStyle['diagonalLine']} />
                  <div className={productStyle['flexItem']}>
                    <h2 className={productStyle['grayTitle']}>Price</h2>
                    <h2 className={productStyle['attributes']}>
                      ${product.price.toFixed(2)}
                    </h2>
                  </div>
                  <div className="flex mt-5 tablet:mt-auto h-auto tablet:h-full items-center justify-center tablet:flex-col gap-6">
                    <Button
                      className="desktop:max-w-10 max-w-8.5"
                      secondaryType={true}
                      onClick={() => {
                        handleModalProps(index, 'details')
                      }}
                      title="View details"
                    />
                    <Button
                      className="desktop:max-w-10 max-w-8.5"
                      onClick={() => {
                        handleModalProps(index, 'edit')
                      }}
                      title="Add to order"
                    />
                  </div>
                </div>
              </div>
            )
          }
          return null
        })}
      {toggleModalVisible && (
        <Modal
          content={modalContent}
          toggle={toggle}
          modalVisible={toggleModalVisible}
          products={products}
          handleClick={addProduct}
          index={index}
          setProducts={setProducts}
        />
      )}
    </div>
  )
}

export default ProductList
