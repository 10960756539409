import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className="flex w-full px-5 py-5 tablet:px-10 tablet:py-10 xl:px-24 ">
      <div className="flex flex-col justify-center tablet:items-start items-center min-w-21rem tablet:w-auto w-full ">
        <div className="font-extrabold text-5xl text-danger mt-10 tablet:mt-20">
          404 ERROR
        </div>
        <div className="font-medium mt-4 text-3xl">Page not found</div>
        <div className="w-full  justify-center tablet:hidden flex ">
          <img
            src={require('../../assets/images/png/404.png').default}
            alt="404"
            className="w-full max-w-10 "
          />
        </div>
        <div className="text-titleSize text-center tablet:text-left mt-10 tablet:mt-32 font-medium">
          The source requested could not be found on this server!
        </div>
        <div className="mt-10 tablet:mt-20 w-full flex justify-center">
          <Link
            to={'/'}
            className=" flex justify-center items-center focus:outline-none text-sm min-h-button-h tablet:text-base
            bg-gradient-to-r shadow-btnShadow active:text-xl font-bold h-14 w-60 from-btnStart to-btnEnd text-white  rounded-2xl">
            Go back
          </Link>
        </div>
      </div>
      <div className="w-full  justify-center hidden tablet:flex ">
        <img
          src={require('../../assets/images/png/404.png').default}
          alt="404"
          className="w-full max-w-30rem -mt-20"
        />
      </div>
    </div>
  )
}

export default NotFound
