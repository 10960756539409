import React, { ChangeEvent } from 'react'
import { ReactComponent as Triangle } from '../../../assets/images/svg/triangle.svg'

interface ICustomDropdownInput {
  value: string
  products: any[]
  index: number
  name: string
  setProducts(product: {}): void
}
const CustomDropdownInput = (props: ICustomDropdownInput) => {
  const { value, products, setProducts, index, name } = props

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newList = [...products]
    const product = { ...newList[index] }
    product[name] = event.target.value
    newList[index][name] = product[name]
    setProducts(newList)
  }

  const renderOptions = () => {
    if (!value) {
      return <option value="--">--</option>
    }
  }
  return (
    <div className="flex  items-center relative">
      <select
        className="appearance-none w-24 h-10 cursor-pointer focus:outline-none border rounded-lg  z-1 px-2"
        value={value}
        onChange={handleChange}>
        {renderOptions()}
        <option value={name === 'hinge' ? 'L' : 'Yes'}>
          {name === 'hinge' ? 'L' : 'Yes'}
        </option>
        <option value={name === 'hinge' ? 'R' : 'No'}>
          {name === 'hinge' ? 'R' : 'No'}
        </option>
      </select>
      <Triangle className="absolute pointer-events-none right-2" />
    </div>
  )
}

export default CustomDropdownInput
