import React, { useContext, useEffect, useState } from 'react'
import { db } from '../../services/firebaseConfig'
import { AuthContext } from '../../services/context/authContext/AuthContext'
import { SearchBar } from '../../components'
import OrderHistoryList from '../../components/listComponents/orderHistoryList/OrderHistoryList'
import SortListComponent from '../../components/listComponents/sortListComponent/SortListComponent'

interface IOrder {
  createdAt: string
  invoiceId: string
  paymentType: string
  isTouchUpKit: boolean
  company: string
  id: string
  createdBy: string
  products: [{ qty: number }]
  totalPrice: number
  isPayed: boolean
  isDelivery: boolean
  isPickup: boolean
  deliveryDate: any
  pickupDate: any
  assembleFee: number
  touchUpKit: number
  subtotal: number
}

const OrderHistory = () => {
  const loggedInUser = useContext(AuthContext)
  const userEmail = loggedInUser && loggedInUser.email
  const [orderListDefault, setOrderListDefault] = useState<IOrder[]>([])
  const [orders, setOrders] = useState<IOrder[]>([])
  const [sortItem, setSortItem] = useState<string>('recent')
  const [isLoading, setIsLoading] = useState(false)
  const [input, setInput] = useState('')
  const sortList = ['recent', 'price', 'paid', 'unpaid']
  const admin = loggedInUser?.role === 'admin'
  const accountant = loggedInUser?.role === 'accountant'

  useEffect(() => {
    const fetchOrders = () => {
      setIsLoading(true)
      const orderRef = db.collection('orders').where('status', '==', 'complete')
      const query =
        admin || accountant
          ? orderRef
          : orderRef
              .where('status', '==', 'complete')
              .where('createdBy', '==', userEmail)
      query.onSnapshot(querySnapshot => {
        const orderList: IOrder[] = []

        querySnapshot.forEach(doc => {
          const orderItem = doc.data()
          // doc.data() is never undefined for query doc snapshots
          orderList.push(orderItem as IOrder)
        })
        setOrderListDefault(orderList)
        setIsLoading(false)
      })
    }
    return fetchOrders()
  }, [userEmail, admin, accountant])
  useEffect(() => {
    const filter = () => {
      let filteredList = [...orderListDefault]
      if (sortItem === 'paid') {
        filteredList = filteredList.filter(order => {
          if (order.isPayed) {
            return order
          }
          return null
        })
        setOrders(filteredList)
        setIsLoading(false)
      } else if (sortItem === 'unpaid') {
        filteredList = filteredList.filter(order => {
          if (!order.isPayed) {
            return order
          }
          return null
        })
        setOrders(filteredList)
        setIsLoading(false)
      } else if (sortItem === 'recent') {
        filteredList.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
        setOrders(filteredList)
        setIsLoading(false)
      } else if (sortItem === 'price') {
        filteredList.sort((a, b) => (a.totalPrice < b.totalPrice ? 1 : -1))
        setOrders(filteredList)
        setIsLoading(false)
      }
    }
    return filter()
  }, [sortItem, orderListDefault])

  const updateInput = async (input: string) => {
    const filtered = orderListDefault.filter((order: IOrder) => {
      if (
        order.company.toLowerCase().includes(input.toLowerCase()) ||
        order.invoiceId.toLowerCase().includes(input.toLowerCase()) ||
        order.createdBy.toLowerCase().includes(input.toLowerCase())
      ) {
        return order
      }
      return null
    })
    setInput(input)
    setOrders(filtered)
  }

  return (
    <div className="px-5 tablet:px-16 desktop:px-20">
      <h2 className="mb-10">
        {admin || accountant ? 'All orders' : 'My orders'}
      </h2>
      <SearchBar keyword={input} setKeyword={updateInput} />
      <div className="flex flex-col mx-4 tablet:mx-0  tablet:flex-row gap-5 mt-16 tablet:items-center ">
        <SortListComponent
          items={sortList}
          item={sortItem}
          setItem={setSortItem}
        />
      </div>
      <OrderHistoryList orders={orders} isLoading={isLoading} />
    </div>
  )
}

export default OrderHistory
