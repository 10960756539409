import React, { createContext, useEffect, useRef, useState } from 'react'

interface IProduct {
  sku: string
  name: string
  description: string
  price: string
  qty: number
  hasHinging: boolean
  hasDrawerOption: boolean
  isCustom: boolean
  drawer?: 'Yes' | 'No'
  hinge?: string
  type: string
}

export const OrderContext = createContext({
  currentOrder: [] as any,
  addToOrder: (val: {}, type: 'add' | 'edit') => {},
  removeFromOrder: (val: {}) => {},
  updateOrder: (val: {}) => {}
})

export const OrderProvider: React.FC = ({ children }) => {
  const [currentOrder, setCurrentOrder] = useState<IProduct[] | []>([])
  const isFirstRender = useRef(true)
  useEffect(() => {
    const storedOrder = localStorage.getItem('currentOrder')
    if (storedOrder) {
      setCurrentOrder(JSON.parse(storedOrder))
    }
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false // toggle flag after first render/mounting
      return
    }
    localStorage.setItem('currentOrder', JSON.stringify(currentOrder))
  }, [currentOrder])

  const updateOrder = (newArray: IProduct[]) => {
    setCurrentOrder(newArray)
  }
  const addToOrder = (product: IProduct, type: 'add' | 'edit') => {
    const newArr = [...currentOrder]
    const targetedProduct = newArr.find(item => {
      return (
        item.sku === product.sku &&
        item.hinge === product.hinge &&
        item.type === product.type &&
        item.drawer === product.drawer &&
        item.isCustom === product.isCustom &&
        item.description === product.description
      )
    })
    if (targetedProduct) {
      const index = newArr.indexOf(targetedProduct)
      const totalQty = newArr[index].qty + product.qty
      if (type === 'add') {
        newArr[index].qty = totalQty < 100 ? totalQty : 100
      } else if (type === 'edit') {
        newArr[index].qty = product.qty < 100 ? product.qty : 100
      }
    } else {
      newArr.push(product)
    }
    updateOrder(newArr)
  }

  const removeFromOrder = (index: number) => {
    const array = [...currentOrder] // make a separate copy of the array
    array.splice(index, 1)
    updateOrder(array)
  }
  const value: any = {
    currentOrder,
    addToOrder,
    removeFromOrder,
    updateOrder: updateOrder
  }

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
}
